import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { HASURA } from "./config/default";
import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";

const httpLink = createHttpLink({
  uri: HASURA.URL,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const { tokens } = await fetchAuthSession();

    if (tokens?.idToken) {
      const token = tokens.idToken.toString();

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return {
      headers,
    };
  } catch (err) {
    console.log("Error in apollo authLink", err);
  }
});

const composedLink = ApolloLink.from([authLink, httpLink]);

export const apolloClient = new ApolloClient({
  link: composedLink,
  cache: new InMemoryCache(),
});
