import React, { useState } from "react";
import { FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import {
  Button,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandLoading,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/RadixWrapper";
import { debounce } from "src/components/helpers";
import FormFieldSkeleton from "./Skeleton";
import clsx from "clsx";
import { cn } from "src/lib/utils";
import { Check, ChevronsUpDown } from "lucide-react";

type SearchableSelectType<TFormValues extends FieldValues> = {
  defaultValue?: string;
  form: UseFormReturn<TFormValues>;
  label: string;
  loadingData: boolean;
  loadingValue: boolean;
  name: Path<TFormValues>;
  onSearch: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder: string;
  selectPlaceholder: string;
  searchResults: {
    label: string;
    value: string;
  }[];
};

export default function SearchableSelect<TFormValues extends FieldValues>({
  defaultValue = "",
  form,
  label,
  loadingData,
  loadingValue,
  name,
  onSearch,
  searchPlaceholder,
  selectPlaceholder,
  searchResults,
}: SearchableSelectType<TFormValues>) {
  const [selectedValueLabel, setSelectedValueLabel] = useState<null | string>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearch = debounce(onSearch, 500);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>{label}</FormLabel>
          {loadingValue && <FormFieldSkeleton />}
          {!loadingValue && (
            <>
              <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                  <FormControl className={clsx({ invisible: isOpen })}>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "w-[286px] justify-between border-input bg-transparent font-normal text-black",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      <span className="max-w-[236px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {field.value
                          ? searchResults.find(
                              (result) => result.value === field.value
                            )?.label ??
                            selectedValueLabel ??
                            defaultValue
                          : selectPlaceholder}
                      </span>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent
                  className={clsx("w-[286px] p-0", { "-mt-10": isOpen })}
                >
                  <Command shouldFilter={false}>
                    <CommandInput
                      placeholder={searchPlaceholder}
                      className="h-9"
                      onValueChange={debouncedSearch}
                    />
                    <CommandGroup>
                      {loadingData && (
                        <CommandLoading>Loading...</CommandLoading>
                      )}
                      {searchResults.map((result) => (
                        <CommandItem
                          value={result.label}
                          key={result.value}
                          onSelect={() => {
                            form.setValue(
                              name,
                              result.value as unknown as PathValue<
                                TFormValues,
                                Path<TFormValues>
                              >
                            );
                            setSelectedValueLabel(result.label);
                            setIsOpen(false);
                          }}
                        >
                          {result.label}
                          <Check
                            className={cn(
                              "ml-auto h-4 w-4",
                              result.value === field.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </>
          )}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
