import React, { useState } from "react";
import styled from "styled-components";
import { Paragraph } from "./Paragraph";

import { ChevronDown } from "lucide-react";

interface SelectBodyInterface {
  open: boolean;
}

interface HeaderInterface {
  padding?: string;
  background?: string;
}

interface TitleInterface {
  marginBottom?: string;
  marginTop?: string;
}

interface SelectContainerProps {
  placeholder?: string;
  width?: string;
  background?: string;
  height?: string;
  padding?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  titleBottom?: string;
  titleTop?: string;
  borderLeft?: string;
  borderRight?: string;
  borderradius?: string;
  fontSize?: string;
  headerPadding?: string;
  id?: string;
  color?: string;
  inputName: string;
  handleChange?: (e: CustomSelectEventInterface) => void;
}

export interface OptionsInterface {
  id?: string;
  name?: string;
}

export interface TargetDataInterface {
  name?: string;
  value?: string;
  id?: string;
}

export interface CustomSelectEventInterface {
  id?: string;
  name: string;
  value: string;
}

interface SelectProps extends SelectContainerProps {
  title?: string;
  options: OptionsInterface[];
  name?: string;
  error?: string;
  value?: string;
  required?: boolean;
}

export const Select = (props: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(
    props?.placeholder || "Search..."
  );

  const handleSelect = (e: CustomSelectEventInterface) => {
    props.handleChange &&
      props.handleChange({
        name: props.inputName,
        value: e.name,
        id: e.id,
      });
    setCurrentValue(e?.value || "");
    setOpen(!open);
  };

  const openSelect = () => {
    setOpen(!open);
  };

  return (
    <Container
      marginBottom={props.marginBottom}
      marginRight={props.marginRight}
      marginLeft={props.marginLeft}
      marginTop={props.marginTop}
      padding={props.padding}
      height={props.height}
      placeholder={props.placeholder}
      width={props.width}
      background={props.background}
      inputName={props.inputName}
    >
      <TitleContainer
        marginTop={props.titleTop}
        marginBottom={props.titleBottom}
      >
        {props.title && (
          <Paragraph padding="0px 0px 10px 0px" text={props?.title || ""} />
        )}
        {props.required && (
          <Paragraph padding="0px 0px 10px 0px" color="red" text="*" />
        )}
      </TitleContainer>
      <SelectBody error={props.error}>
        <SelectHeader
          background={props.background}
          padding={props.headerPadding}
          onClick={openSelect}
        >
          <Paragraph
            fontSize={props.fontSize}
            color={props.color || "#7F8389"}
            text={currentValue}
          />
          <ChevronDown className="h-4 w-4" />
        </SelectHeader>
        <SelectOptionsContainer open={open}>
          {props?.options?.map((item: OptionsInterface) => (
            <SelectOption
              key={item?.name || item?.id}
              background={props.background}
            >
              <Paragraph
                fontSize={props.fontSize}
                onClick={() =>
                  handleSelect({
                    name: props.inputName,
                    value: `${item.name}`,
                    id: item.id,
                  })
                }
                color={props.color || "#7F8389"}
                text={item.name}
                id={item.id || props.id}
              />
            </SelectOption>
          ))}
        </SelectOptionsContainer>
      </SelectBody>

      {props.error && (
        <Paragraph fontSize="12px" color="red" text={props.error} />
      )}
    </Container>
  );
};

const Container = styled.div<SelectContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "0px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "0px")};
  background: ${({ background }) => (background ? background : "#ff")};
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "100%")};
  padding: ${({ padding }) => (padding ? padding : "0px")};
`;

const SelectBody = styled.div<{ error?: string }>`
  position: relative;
  border: ${({ error }) => (error ? "1px solid #FF5252" : "none")};
  border-radius: 4px;
`;

const SelectHeader = styled.div<HeaderInterface>`
  display: flex;
  padding: ${({ padding }) => (padding ? padding : "10px 9px 10px 16px")};
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  background: ${({ background }) => (background ? background : "#f0f5ff;")};
`;

const SelectOptionsContainer = styled.div<SelectBodyInterface>`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  border-top: 1px solid #7f8389;
  position: absolute;
  width: 100%;
  z-index: 100;
`;

const SelectOption = styled.li<HeaderInterface>`
  list-style: none;
  background: ${({ background }) => (background ? background : "#f0f5ff;")};
  padding: ${({ padding }) => (padding ? padding : "10px 9px 10px 16px")};
  border-radius: 2px;
`;

const TitleContainer = styled.div<TitleInterface>`
  display: flex;
  gap: 5px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "0px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "0px")};
`;
