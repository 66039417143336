import gql from "graphql-tag";

export interface questions_bool_exp {
  question: string_comparison_exp;
  answer: string_comparison_exp;
  _or: questions_bool_exp;
}

export interface regions_bool_exp {
  name: string_comparison_exp;
  _or: questions_bool_exp;
}

export interface events_bool_exp {
  id: string_comparison_exp;
  org_id?: string_comparison_exp;
  account_id?: string_comparison_exp;
  name?: string_comparison_exp;
  eventDateTime?: timestamptz_comparison_exp;
  status?: string_comparison_exp;
}

export interface accounts_bool_exp {
  name: string_comparison_exp;
}

export interface eventQuestions_bool_exp {
  event_id: uuid_comparison_exp;
}

export const GET_USER_WITH_EMAIL = gql`
  query GetUser($id: String!) {
    users_by_pk(id: $id) {
      email
      id
      settings
    }
  }
`;

export const GET_ACCOUNT_LOCATIONS = gql`
  query GetAccountLocations {
    locations(limit: 10) {
      id
      name
      address
      city
      state
      zip
    }
  }
`;

export const GET_ACCOUNT_LOCATION_BY_ID = gql`
  query GetAccountLocationById($id: uuid!) {
    locations_by_pk(id: $id) {
      address
      city
      name
      state
      updated_at
      zip
      id
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query GetAccounts($limit: Int, $offset: Int, $where: accounts_bool_exp) {
    accounts(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { name: asc }
    ) {
      id
      name
      region {
        created_at
        id
        name
        org_id
        updated_at
      }
      region_id
    }
    accounts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_REGIONS = gql`
  query GetRegions($limit: Int, $offset: Int, $where: regions_bool_exp) {
    regions(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { name: asc }
    ) {
      id
      name
    }
    regions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ACCOUNT_BY_ID = gql`
  query GetAccount($id: uuid!) {
    accounts_by_pk(id: $id) {
      id
      name
      region {
        created_at
        id
        name
        org_id
        updated_at
      }
      region_id
    }
  }
`;

export const GET_ACCOUNT_QUESTIONS = gql`
  query GetQuestions($where: questions_bool_exp, $limit: Int, $offset: Int) {
    questions(limit: $limit, offset: $offset, where: $where) {
      id
      question
      isTimeSensitive
      updated_at
      answer
      org_id
      created_at
      category {
        name
        id
      }
      category_id
      questionType {
        name
        id
      }
      questionTags {
        id
        tag {
          id
          name
        }
      }
      type_id
      status
    }
    questions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const Get_Categories = gql`
  query GetCategories($where: categories_bool_exp) {
    categories(where: $where) {
      id
      name
    }
  }
`;

export const Get_QuestionTypes = gql`
  query GetQuestionTypes {
    questionTypes {
      id
      name
    }
  }
`;

export interface string_comparison_exp {
  _eq?: string | boolean;
  _gt?: string;
  _gte?: string;
  _ilike?: string;
  _in?: string[];
  _iregex?: string;
  _is_null?: boolean;
  _like: string;
  _lt?: string;
  _lte?: string;
  _neq?: string;
  _nilike?: string;
  _nin?: string[];
  _niregex?: string;
  _nlike?: string;
  _nregex?: string;
  _nsimilar?: string;
  _regex?: string;
  _similar?: string;
}

export interface uuid_comparison_exp {
  _eq?: string;
  _gt?: string;
  _gte?: string;
  _in?: [string];
  _is_null?: boolean;
  _lt?: string;
  _lte?: string;
  _neq?: string;
  _nin?: [string];
}

export interface Int_comparison_exp {
  _eq?: number;
  _gt?: number;
  _gte?: number;
  _in?: [number];
  _is_null?: boolean;
  _lt?: number;
  _lte?: number;
  _neq?: number;
  _nin?: [number];
}

export interface timestamptz_comparison_exp extends uuid_comparison_exp {}
export interface boolean_comparison_exp extends uuid_comparison_exp {}

export interface accounts_select_column {
  id: string;
}

export interface notebook_question {
  org_id?: string_comparison_exp;
  id?: string_comparison_exp;
  question_id?: string_comparison_exp;
  notebook_id?: string_comparison_exp;
}

export interface notebooks_bool_exp {
  id: string_comparison_exp;
  org_id?: string_comparison_exp;
  name?: string_comparison_exp;
  owner_id?: string_comparison_exp;
  isArchived?: boolean_comparison_exp;
  isStarred?: boolean_comparison_exp;
  created_at?: string_comparison_exp;
  updated_at?: string_comparison_exp;
  notebooks_notebookQuestions?: notebook_question;
}

export enum order_by {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",

  asc_nulls_last = "asc_nulls_last",

  desc = "desc",

  desc_nulls_first = "desc_nulls_first",

  desc_nulls_last = "desc_nulls_last",
}

export interface notebooks_order_by {
  org_id?: order_by;
  created_at?: order_by;
  id?: order_by;
  isArchived?: order_by;
  name?: order_by;
  owner_id?: order_by;
  updated_at?: order_by;
}

export interface FindNotebookInput {
  distinct_on?: accounts_select_column[];
  limit?: number;
  offset?: number;
  order_by?: notebooks_order_by;
  where?: notebooks_bool_exp;
}

export const Get_NoteBooks = gql`
  query GetNotebooks(
    $where: notebooks_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [notebooks_order_by!]
  ) {
    notebooks(
      where: $where
      limit: $limit
      order_by: $order_by
      offset: $offset
    ) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions {
        id
        question_id
        sortOrder
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      updated_at
      isStarred
    }
  }
`;

export const Get_Notebooks_Aggregate = gql`
  query GetNotebooksAggregate($where: notebooks_bool_exp) {
    notebooks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($where: events_bool_exp, $limit: Int, $offset: Int) {
    events(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { eventDateTime: asc }
    ) {
      id
      account_id
      eventDateTime
      eventQuestions_aggregate {
        aggregate {
          count
        }
      }
      location_id
      location {
        id
        city
        name
        state
      }
      name
      org_id
      status
      cost
      region_id
      region {
        id
        name
      }
    }
    events_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query GetEventById($id: uuid!) {
    events_by_pk(id: $id) {
      account {
        name
        id
      }
      eventDateTime
      eventQuestions(order_by: { sortOrder: asc }) {
        sortOrder
        question {
          category {
            name
            id
          }
          id
          question
          answer
        }
      }
      id
      name
      location {
        id
        name
      }
      status
      region_id
      cost
      region {
        id
        name
      }
    }
  }
`;

export const GET_EVENT_QUESTIONS = gql`
  query GetEventQuestions($where: eventQuestions_bool_exp) {
    eventQuestions(where: $where) {
      created_at
      event_id
      id
      notes
      org_id
      question_id
      updated_at
    }
  }
`;
