import { Trash } from "lucide-react";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
} from "../RadixWrapper";

type Host = {
  name: string;
  cost: number;
};

type HostEditTableProps = {
  hosts: Host[];
  setHosts: React.Dispatch<React.SetStateAction<Host[]>>;
  totalCost: number;
};

const HostEditTable = ({ hosts, setHosts, totalCost }: HostEditTableProps) => {
  const handleRemoveHost = (index: number) => {
    setHosts((currentHosts: Host[]) =>
      currentHosts.filter((_, i) => i !== index)
    );
  };

  const totalHostCost = hosts.reduce((sum, host) => sum + host.cost, 0);
  const eventProfit = totalCost - totalHostCost;

  return (
    <Table className="min-w-full table-auto">
      <TableHeader className="bg-gray-200">
        <TableRow>
          <TableCell className="px-4 py-2">Host Name</TableCell>
          <TableCell className="px-4 py-2">Role</TableCell>
          <TableCell className="px-4 py-2">Cost</TableCell>
          <TableCell className="px-4 py-2"></TableCell>{" "}
          {/* Empty header for the trash icon column */}
        </TableRow>
      </TableHeader>
      <TableBody>
        {hosts.map((host, index) => (
          <TableRow key={index} className="border-b">
            <TableCell className="px-4 py-2">{host.name}</TableCell>
            <TableCell className="px-4 py-2">
              <select className="rounded-md border p-1">
                <option value="Host">Host</option>
                <option value="Co-Host">Co-Host</option>
                <option value="Scoring Assistant">Scoring Assistant</option>
              </select>
            </TableCell>
            <TableCell className="px-4 py-2">
              <input
                type="text"
                className="rounded-md border p-1"
                placeholder="$0.00"
                pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                value={host.cost}
                onChange={(e) =>
                  setHosts((currentHosts: Host[]) =>
                    currentHosts.map((h, i) =>
                      i === index ? { ...h, cost: +e.target.value } : h
                    )
                  )
                }
              />
            </TableCell>
            <TableCell className="px-4 py-2 text-right">
              <button
                type="button"
                className="text-red-500 hover:text-red-700"
                onClick={() => handleRemoveHost(index)}
              >
                <Trash className="h-5 w-5" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            Host Cost:{" "}
            {totalHostCost >= 0 ? (
              `$${totalHostCost.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${totalHostCost.toLocaleString()}
              </span>
            )}
          </TableCell>
          <TableCell>
            Event Profit:{" "}
            {eventProfit >= 0 ? (
              `$${eventProfit.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${eventProfit.toLocaleString()}
              </span>
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default HostEditTable;
