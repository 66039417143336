import { useMutation, useQuery } from "@apollo/client";
import dateFormat from "dateformat";
import { Edit, Loader, Trash2 } from "lucide-react";
import { FC, default as React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EventInterface } from "src/types/Event";
import { CategoryInterface } from "src/types/Questions";
import styled from "styled-components";
import { Delete_Event_Mutation, Get_Categories } from "../../gql";
import {
  setEvents,
  useAppDispatch,
  useAppSelector,
  usePubSubInstance,
} from "../../store";
import { Paragraph } from "../Common";
import { Button } from "../RadixWrapper";
import { removeArrayItem } from "../helpers";
import { CreateEventForm } from "./EventForm";

interface ItemSectionProps {
  width?: string;
}

export interface ListItemProps extends EventInterface {}

export const ListItem: FC<ListItemProps> = (props) => {
  const dispatch = useAppDispatch();
  const [openEditor, setOpenEditor] = useState(false);
  const [deleteEventMutation, { loading: removingEvent }] = useMutation(
    Delete_Event_Mutation
  );
  const { pubSubInstance } = usePubSubInstance();

  const { events } = useAppSelector((state) => {
    return {
      events: state.eventReducer.events,
    };
  });

  const navigate = useNavigate();

  const handleCancel = () => {
    setOpenEditor(false);
    pubSubInstance &&
      pubSubInstance.publish({
        channel: "text_channel",
        message: { question: props, type: "editing_canceled" },
      });
  };

  const { data: categoriesData } = useQuery(Get_Categories);

  const handleSubmitForm = () => {
    setOpenEditor(false);
  };

  const handleRemoveEvent = async (itemId: string) => {
    const removeEvent = await deleteEventMutation({
      variables: {
        id: itemId,
      },
    });

    const updatedEvents = removeArrayItem(
      events,
      removeEvent.data.delete_events_by_pk
    );

    dispatch(setEvents({ events: updatedEvents }));

    pubSubInstance &&
      pubSubInstance.publish({
        channel: "text_channel",
        message: { events: updatedEvents, type: "event_updated" },
      });
  };

  // const openEventEditor = async (event: React.MouseEvent<SVGElement>) => {
  //   event.stopPropagation();
  //   setOpenEditor(true);

  //   (await pubSubInstance) &&
  //     pubSubInstance?.publish({
  //       channel: "text_channel",
  //       message: { event: props, type: "editing_event_form" },
  //     });
  // };

  const questionFormProps = {
    categories:
      categoriesData &&
      categoriesData.categories.map((item: CategoryInterface) => {
        return { name: item.name, id: item.id };
      }),
  };

  const getText = (count: number | undefined) => {
    if (typeof count === "number") {
      if (count === 1) {
        return "1 question";
      }

      return `${count} questions`;
    }

    return "";
  };

  return (
    <>
      {openEditor ? (
        <CreateEventForm
          {...props}
          {...questionFormProps}
          handleCancel={handleCancel}
          handleSubmit={handleSubmitForm}
        />
      ) : (
        <Container draggable={false}>
          <ItemSectionContainer width="20%">
            <Button variant="link">
              <Link to={`/events/${props.id}/view`}>
                <Paragraph text={props?.name} />
              </Link>
            </Button>
          </ItemSectionContainer>
          <ItemSectionContainer width="20%">
            <Paragraph
              text={dateFormat(props.eventDateTime, "dddd, mmmm dS, yyyy")}
            />
          </ItemSectionContainer>
          <ItemSectionContainer width="10%">
            {/* <Paragraph text={faker.location.city()} /> */}
          </ItemSectionContainer>

          <ItemSectionContainer width="10%">
            {/* <Paragraph text={faker.finance.amount()} /> */}
          </ItemSectionContainer>

          <ItemSectionContainer width="20%">
            {/* <Paragraph
              background="#D9D9D9"
              borderradius="50%"
              width="20px"
              height="20px"
              padding="2px"
              fontWeight="400"
              text={faker.person.firstName().substring(1, 2).toUpperCase()}
            />

            <Paragraph
              background="#D9D9D9"
              borderradius="50%"
              width="20px"
              height="20px"
              padding="2px"
              fontWeight="400"
              text={faker.person.firstName().substring(1, 2).toUpperCase()}
            /> */}
          </ItemSectionContainer>

          <ItemSectionContainer width="20%">
            <span className="text=sm">
              {getText(props.eventQuestions_aggregate?.aggregate?.count)}
            </span>
          </ItemSectionContainer>

          <IconSectionContainer width="10%">
            <IconContainer>
              <Edit
                onClick={() => navigate(`/events/${props.id}/edit`)}
                className="h-4 w-4"
              />
            </IconContainer>
            <IconContainer>{/* <Copy className="h-4 w-4" /> */}</IconContainer>
            <IconContainer>
              {removingEvent ? (
                <Loader className="h-4 w-4" />
              ) : (
                <Trash2
                  onClick={() => handleRemoveEvent(props.id)}
                  className="h-4 w-4"
                />
              )}
            </IconContainer>
            {/* <IconContainer>
              <FileSymlink className="h-4 w-4" />
            </IconContainer> */}
          </IconSectionContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #d1d1d1;
  background: #fff;
`;

const ItemSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: center;
  text-align: center;
  gap: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;
