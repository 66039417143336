type DebounceFunction<T extends unknown[]> = (...args: T) => void;

export const debounce = <T extends unknown[]>(
  func: DebounceFunction<T>,
  delay: number
): DebounceFunction<T> => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: T) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
