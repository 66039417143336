import { useMutation } from "@apollo/client";
import React, { Suspense, useEffect, useState } from "react";
import { add } from "date-fns";
import {
  Alert,
  AlertDescription,
  Button,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  ToggleGroup,
  ToggleGroupItem,
} from "src/components/RadixWrapper";
import { Create_Event_Questions, Delete_Event_Questions } from "src/gql";
import { NoteBookQuestionInterface } from "src/types/Notebook";
import { DialogClose } from "@radix-ui/react-dialog";
import { AlertOctagon } from "lucide-react";
import { useToast } from "src/components/RadixWrapper/UseToast";
import clsx from "clsx";
import { EventList } from "./EventList";
import { EventListSkeleton } from "./Skeleton";

type EventManagerProp = {
  questions: NoteBookQuestionInterface[];
  onCompletion: () => void;
};

enum DATE_RANGE_TYPES {
  TODAY = "today",
  TOMORROW = "tomorrow",
  THIS_WEEK = "this week",
  THIS_MONTH = "this month",
}

const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
const todayEnd = new Date(new Date().setHours(23, 59, 59, 999));

export function EventManager({ questions, onCompletion }: EventManagerProp) {
  const [checkedEvents, setCheckedEvents] = useState(
    {} as Record<string, boolean>
  );
  const [dateRangeType, setDateRangeType] = useState(
    DATE_RANGE_TYPES.TODAY as string
  );
  const [dateRange, setDateRange] = useState({
    startDate: todayStart.toISOString(),
    endDate: todayEnd.toISOString(),
  });
  const [nonEmptyEvents, setNonEmptyEvents] = useState([] as string[]);
  const [addInProgress, setAddInProgress] = useState(false);

  const { toast } = useToast();
  const [deleteEventQuestions] = useMutation(Delete_Event_Questions);
  const [createEventQuestions] = useMutation(Create_Event_Questions);

  const updateDateRange = (value: string) => {
    setDateRangeType(value);
    setNonEmptyEvents([] as string[]);
  };

  const onUpdateCheckedEvents = (eventId: string, checked: boolean) => {
    setCheckedEvents((prev) => ({ ...prev, [eventId]: checked }));
  };

  const onNonEmptySelection = (eventId: string, isActive: boolean) => {
    setNonEmptyEvents((prev) => {
      if (isActive) {
        const index = prev.indexOf(eventId);

        if (index === -1) {
          return [...prev, eventId];
        }
      } else {
        const index = prev.indexOf(eventId);

        if (index !== -1) {
          return prev.filter((item) => item !== eventId);
        }
      }

      return [...prev];
    });
  };

  const sleep = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const removeQFromEvents = async () => {
    if (nonEmptyEvents.length > 0) {
      toast({
        variant: "destructive",
        title: "Deletion in progress",
        description:
          "Deleting existing questions associated with one or more events selected. This may take some time...",
      });

      for (let i = 0; i < nonEmptyEvents.length; i++) {
        await deleteEventQuestions({
          variables: {
            where: {
              event_id: {
                _eq: nonEmptyEvents[i],
              },
            },
          },
        });

        if (i + 1 < nonEmptyEvents.length) {
          // More deletion to come - pause next request to prevent rate limit issues
          await sleep(3000);
        }
      }

      toast({
        title: "Success",
        description: "Existing questions deleted successfully",
      });
    }
  };

  const addQToEvents = async () => {
    setAddInProgress(true);

    await removeQFromEvents();

    toast({
      title: "In Progress",
      description: "Associating questions to events...",
    });

    const eventIds = Object.keys(checkedEvents).filter(
      (item) => !!checkedEvents[item]
    );

    for (let i = 0; i < eventIds.length; i++) {
      const objects = questions.map((q) => ({
        question_id: q.question_id,
        event_id: eventIds[i],
        notes: ".",
        sortOrder: q.sortOrder,
      }));

      console.log(objects);

      await createEventQuestions({
        variables: {
          objects,
        },
      });

      if (i + 1 < eventIds.length) {
        // Prevent rate limit errors
        await sleep(3000);
      }
    }

    toast({
      title: "Completed",
      description: "Questions associated with selected event(s) successfully.",
    });

    setAddInProgress(false);

    onCompletion();
  };

  useEffect(() => {
    let newStartDate: string;
    let newEndDate: string;

    if (dateRangeType === (DATE_RANGE_TYPES.TODAY as string)) {
      newStartDate = todayStart.toISOString();
      newEndDate = todayEnd.toISOString();

      setDateRange({ startDate: newStartDate, endDate: newEndDate });
      setCheckedEvents({} as Record<string, boolean>);
    } else if (dateRangeType === (DATE_RANGE_TYPES.TOMORROW as string)) {
      newStartDate = add(new Date(todayStart), { days: 1 }).toISOString();
      newEndDate = add(new Date(todayEnd), { days: 1 }).toISOString();

      setDateRange({ startDate: newStartDate, endDate: newEndDate });
      setCheckedEvents({} as Record<string, boolean>);
    } else if (dateRangeType === (DATE_RANGE_TYPES.THIS_WEEK as string)) {
      newStartDate = todayStart.toISOString();
      newEndDate = add(new Date(todayEnd), { weeks: 1 }).toISOString();

      setDateRange({ startDate: newStartDate, endDate: newEndDate });
      setCheckedEvents({} as Record<string, boolean>);
    } else if (dateRangeType === (DATE_RANGE_TYPES.THIS_MONTH as string)) {
      newStartDate = todayStart.toISOString();
      newEndDate = add(new Date(todayEnd), { months: 1 }).toISOString();

      setDateRange({ startDate: newStartDate, endDate: newEndDate });
      setCheckedEvents({} as Record<string, boolean>);
    }
  }, [dateRangeType]);

  return (
    <DialogContent className="max-w-3xl">
      <DialogHeader className="border-b pb-2">
        <DialogTitle>Add questions to events</DialogTitle>
        <DialogDescription>
          Here you can associated your questions in the notebook to one or more
          events
        </DialogDescription>
      </DialogHeader>
      <div className="flex gap-1">
        <ScrollArea className="max-h-80 w-48 p-2" type="auto">
          {questions.length === 0 && (
            <div className="flex h-72 items-center justify-center text-sm">
              No questions
            </div>
          )}
          <ul className="list-inside list-decimal">
            {questions.map((q) => (
              <li
                key={q.question_id}
                className="mb-1 w-32 truncate text-sm"
                title={q.notebookQuestions_question.question}
              >
                {q.notebookQuestions_question.question}
              </li>
            ))}
          </ul>
        </ScrollArea>
        <section className="w-full px-4 pb-4">
          <ToggleGroup
            type="single"
            variant="outline"
            defaultValue={dateRangeType}
            onValueChange={updateDateRange}
            className="mb-4"
          >
            <ToggleGroupItem value={DATE_RANGE_TYPES.TODAY} aria-label="Today">
              Today
            </ToggleGroupItem>
            <ToggleGroupItem
              value={DATE_RANGE_TYPES.TOMORROW}
              aria-label="Tomorrow"
            >
              Tomorrow
            </ToggleGroupItem>
            <ToggleGroupItem
              value={DATE_RANGE_TYPES.THIS_WEEK}
              aria-label="This week"
            >
              Next 7 days
            </ToggleGroupItem>
            <ToggleGroupItem
              value={DATE_RANGE_TYPES.THIS_MONTH}
              aria-label="This month"
            >
              Next 30 days
            </ToggleGroupItem>
          </ToggleGroup>
          <Suspense fallback={<EventListSkeleton />}>
            <EventList
              dateRange={dateRange}
              key={`${dateRange.startDate}-${dateRange.endDate}`}
              updateCheckedEvents={onUpdateCheckedEvents}
              warnAboutSelection={onNonEmptySelection}
            />
          </Suspense>
        </section>
      </div>
      <DialogFooter className="-mb-4 items-center justify-between border-t pt-2">
        <section
          className={clsx({
            invisible: nonEmptyEvents.length === 0,
          })}
        >
          <Alert variant="destructive">
            <AlertOctagon className="h-4 w-4" />
            <AlertDescription className="text-sm">
              A selected event already has questions attached. You will replace
              the questions attached to those events.
            </AlertDescription>
          </Alert>
        </section>
        <DialogClose asChild>
          <Button variant="secondary">Cancel</Button>
        </DialogClose>
        <Button
          variant="default"
          onClick={addQToEvents}
          disabled={
            addInProgress ||
            questions.length == 0 ||
            Object.keys(checkedEvents).length === 0
          }
        >
          {addInProgress ? "Adding" : "Add"}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
