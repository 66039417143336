import React from "react";
import Dropdown from "../Common/Dropdown";
import { Regions, useGetRegionsQuery } from "src/generated/graphql";
import { Button } from "src/components/RadixWrapper";
import { Plus } from "lucide-react";

interface Region {
  id: string;
  name: string;
}

interface RegionsSelectProps {
  // regions: Region[];
  selectedRegion: Region | undefined;
  onRegionChange: (region: Regions) => void;
  onCreateButtonClick?: () => void;
}

const RegionsSelect: React.FC<RegionsSelectProps> = ({
  selectedRegion,
  onRegionChange,
  onCreateButtonClick,
}) => {
  const [filterValue, setFilterValue] = React.useState("");
  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   onRegionChange(event.target.value);
  // };

  const { data } = useGetRegionsQuery({
    variables: {
      limit: 50,
      where: { name: { _ilike: `%${filterValue}%` } },
    },
    fetchPolicy: "network-only",
  });
  const onFilterStart = (filter: string) => {
    // console.log("Filtering", filter);
    setFilterValue(filter);
  };
  const onChange = (region: Regions) => {
    // console.log("Selected region:", region);
    onRegionChange(region);
  };

  // if (!data) return <></>;

  return (
    <div className="flex flex-row items-end">
      <Dropdown<Regions>
        selectedItem={selectedRegion as Regions}
        idColumn="id"
        labelColumn="name"
        items={(data?.regions as Regions[]) || []}
        onSelect={onChange}
        setFilter={onFilterStart}
      />
      {onCreateButtonClick && (
        <Button variant="ghost" onClick={onCreateButtonClick}>
          <Plus />
        </Button>
      )}
    </div>
  );
};

export default RegionsSelect;
