import { Trash } from "lucide-react";
import React from "react";
import { EventHosts } from "src/generated/graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../RadixWrapper";

type HostEditTableProps = {
  eventHosts: EventHosts[];
  // setHosts: React.Dispatch<React.SetStateAction<Hosts[]>>;
  // totalCost: number;
};

const HostEditTable = ({ eventHosts }: HostEditTableProps) => {
  // const handleRemoveHost = (index: number) => {
  // setHosts((currentHosts: Hosts[]) =>
  //   currentHosts.filter((_, i) => i !== index)
  // );
  // };

  // const totalHostCost = hosts.reduce((sum, host) => sum + host.cost, 0);
  // const eventProfit = totalCost - totalHostCost;

  return (
    <Table className="min-w-full table-auto">
      <TableHeader className="bg-gray-200">
        <TableRow>
          <TableCell className="px-4 py-2">Host Name</TableCell>
          <TableCell className="px-4 py-2">Role</TableCell>
          <TableCell className="px-4 py-2">Cost</TableCell>
          <TableCell className="px-4 py-2"></TableCell>{" "}
          {/* Empty header for the trash icon column */}
        </TableRow>
      </TableHeader>
      <TableBody>
        {eventHosts?.map((eventHost: EventHosts) => (
          <TableRow key={eventHost.id} className="border-b">
            <TableCell className="px-4 py-2">
              {eventHost.host.nickname}
            </TableCell>
            <TableCell className="px-4 py-2">
              <select className="rounded-md border p-1">
                <option value="HOST">Host</option>
                <option value="SCORING">Scoring Assistant</option>
              </select>
            </TableCell>
            <TableCell className="px-4 py-2">
              <input
                type="text"
                className="rounded-md border p-1"
                placeholder="$0.00"
                // pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                value={eventHost.pay || 0}
                onChange={(e) => (eventHost.pay = e.currentTarget.value)}
                // setHosts((currentHosts: Hosts[]) =>
                //   currentHosts.map((h, i) =>
                //     i === index ? { ...h, cost: +e.target.value } : h
                //   )
                // )
                // }
              />
            </TableCell>
            <TableCell className="px-4 py-2 text-right">
              <button
                type="button"
                className="text-red-500 hover:text-red-700"
                // onClick={() => handleRemoveHost(index)}
              >
                <Trash className="h-5 w-5" />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {/* <TableFooter>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            Host Cost:{" "}
            {totalHostCost >= 0 ? (
              `$${totalHostCost.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${totalHostCost.toLocaleString()}
              </span>
            )}
          </TableCell>
          <TableCell>
            Event Profit:{" "}
            {eventProfit >= 0 ? (
              `$${eventProfit.toLocaleString()}`
            ) : (
              <span style={{ color: "red" }}>
                ${eventProfit.toLocaleString()}
              </span>
            )}
          </TableCell>
        </TableRow>
      </TableFooter> */}
    </Table>
  );
};

export default HostEditTable;
