import React from "react";
import Dropdown from "../Common/Dropdown";
import { Locations, useGetLocationsQuery } from "src/generated/graphql";
import { Button } from "src/components/RadixWrapper";
import { Plus } from "lucide-react";

interface Location {
  id: string;
  name: string;
}

interface LocationsSelectProps {
  // locations: Location[];
  selectedLocation: Location | undefined;
  onLocationChange: (location: Locations) => void;
  onCreateButtonClick?: () => void;
}

const LocationsSelect: React.FC<LocationsSelectProps> = ({
  selectedLocation,
  onLocationChange,
  onCreateButtonClick,
}) => {
  const [filterValue, setFilterValue] = React.useState("");
  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   onLocationChange(event.target.value);
  // };

  const { data } = useGetLocationsQuery({
    variables: {
      limit: 50,
      where: { name: { _ilike: `%${filterValue}%` } },
    },
    fetchPolicy: "network-only",
  });
  const onFilterStart = (filter: string) => {
    // console.log("Filtering", filter);
    setFilterValue(filter);
  };
  const onChange = (location: Locations) => {
    // console.log("Selected location:", location);
    onLocationChange(location);
  };

  // if (!data) return <></>;

  return (
    <div className="flex flex-row items-end">
      <Dropdown<Locations>
        selectedItem={selectedLocation as Locations}
        idColumn="id"
        labelColumn="name"
        items={(data?.locations as Locations[]) || []}
        onSelect={onChange}
        setFilter={onFilterStart}
      />
      {onCreateButtonClick && (
        <Button variant="ghost" onClick={onCreateButtonClick}>
          <Plus />
        </Button>
      )}
    </div>
  );
};

export default LocationsSelect;
