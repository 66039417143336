import React, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";
import { Paragraph, Input } from "../Common";
import {
  setEvents,
  useAppDispatch,
  useAppSelector,
  usePubSubInstance,
} from "../../store";
import { useMutation } from "@apollo/client";
import { Update_Event_Mutation } from "../../gql";
import { updateObjectArray } from "../helpers/array.helpers";
import { EventInterface } from "src/types/Event";
import { shallowEqual } from "react-redux";
import { IconContainer } from "./ListItem";
import { Check, Loader, X } from "lucide-react";

interface ItemSectionProps {
  width?: string;
}

interface ListItemProps extends EventInterface {
  id: string;
  name: string;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export interface FormInterface {
  id?: string;
  name?: string;
  eventDateTime?: string;
  cost?: string;
}

export const CreateEventForm: FC<ListItemProps> = (props: ListItemProps) => {
  const [updateEvent, { loading: updatingEvent }] = useMutation(
    Update_Event_Mutation
  );
  const [formInput, setFormInput] = useState<FormInterface>({
    id: props.id,
    name: props.name,
  });

  const { events } = useAppSelector((state) => {
    return {
      events: state.eventReducer.events,
    };
  }, shallowEqual);

  const dispatch = useAppDispatch();

  const { pubSubInstance } = usePubSubInstance();

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFormInput({
      ...formInput,
      [e?.target?.name || e?.target?.id]: e?.target?.id || e?.target?.value,
    });
  };

  const handleFormSub = async () => {
    try {
      const eventId = formInput.id;
      delete formInput.id;

      const { data } = await updateEvent({
        variables: {
          pk_columns: { id: eventId },
          _set: { ...formInput },
        },
      });

      console.log({ events });

      const updatedEvents = updateObjectArray(
        events as EventInterface[],
        data.update_events_by_pk
      );

      dispatch(setEvents({ events: updatedEvents }));

      pubSubInstance &&
        pubSubInstance.publish({
          channel: "text_channel",
          message: {
            events: updatedEvents,
            type: "event_updated",
          },
        });

      props.handleSubmit();
    } catch (error) {
      console.log({ error });
      props.handleSubmit();
    }
  };

  return (
    <Container>
      <ItemSectionContainer width="40%">
        <Paragraph text="Event name" />
        <Input
          placeholder="Event name"
          marginTop="8px"
          name="name"
          handleChange={handleInputChange}
          value={formInput.name}
        />
      </ItemSectionContainer>
      <ItemSectionContainer width="40%">
        <Paragraph text="Cost" />
        <Input
          placeholder={props.cost}
          marginTop="8px"
          handleChange={handleInputChange}
          name="Cost"
          value={formInput.cost}
        />
      </ItemSectionContainer>

      <IconSectionContainer width="10%">
        <IconContainer>
          {updatingEvent ? (
            <Loader className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" onClick={handleFormSub} />
          )}
        </IconContainer>
        <IconContainer>
          <X className="h-4 w-4" onClick={props.handleCancel} />
        </IconContainer>
      </IconSectionContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #d1d1d1;
`;

const ItemSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  flex-direction: column;
  margin-right: 25px;
`;

const IconSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  gap: 14px;
`;
