import { useMutation } from "@apollo/client";
import { Edit, Loader, Trash2 } from "lucide-react";
import { FC, default as React, useState } from "react";
import { EventQuestions } from "src/generated/graphql";
import styled from "styled-components";
import { Delete_Event_Mutation } from "../../gql";
import {
  setEvents,
  useAppDispatch,
  useAppSelector,
  usePubSubInstance,
} from "../../store";
import { Paragraph } from "../Common";
import { removeArrayItem } from "../helpers";

interface ItemSectionProps {
  width?: string;
}

export interface ListItemProps extends EventQuestions {}

export const EventQuestionListItem: FC<ListItemProps> = (props) => {
  const dispatch = useAppDispatch();
  const [, setOpenEditor] = useState(false);
  const [deleteEventMutation, { loading: removingEvent }] = useMutation(
    Delete_Event_Mutation
  );
  const { pubSubInstance } = usePubSubInstance();

  const { events } = useAppSelector((state) => {
    return {
      events: state.eventReducer.events,
    };
  });

  // const handleCancel = () => {
  //   setOpenEditor(false);
  //   pubSubInstance &&
  //     pubSubInstance.publish({
  //       channel: "text_channel",
  //       message: { question: props, type: "editing_canceled" },
  //     });
  // };

  // const { data: categoriesData } = useQuery(Get_Categories);

  // const handleSubmitForm = () => {
  //   setOpenEditor(false);
  // };

  const handleRemoveEvent = async (itemId: string) => {
    const removeEvent = await deleteEventMutation({
      variables: {
        id: itemId,
      },
    });

    const updatedEvents = removeArrayItem(
      events,
      removeEvent.data.delete_events_by_pk
    );

    dispatch(setEvents({ events: updatedEvents }));

    pubSubInstance &&
      pubSubInstance.publish({
        channel: "text_channel",
        message: { events: updatedEvents, type: "event_updated" },
      });
  };

  const openEventEditor = async (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    setOpenEditor(true);

    (await pubSubInstance) &&
      pubSubInstance?.publish({
        channel: "text_channel",
        message: { event: props, type: "editing_event_form" },
      });
  };

  // const questionFormProps = {
  //   categories:
  //     categoriesData &&
  //     categoriesData.categories.map((item: CategoryInterface) => {
  //       return { name: item.name, id: item.id };
  //     }),
  // };

  // const getText = (count: number | undefined) => {
  //   if (typeof count === "number") {
  //     if (count === 1) {
  //       return "1 question";
  //     }

  //     return `${count} questions`;
  //   }

  //   return "";
  // };

  return (
    <>
      <Container draggable={false}>
        <ItemSectionContainer width="10%">
          <Paragraph text={`${props.sortOrder}`} />
        </ItemSectionContainer>
        <ItemSectionContainer width="50%">
          <Paragraph text={props?.question?.question} />
        </ItemSectionContainer>
        <ItemSectionContainer width="30%">
          <Paragraph text={props.question?.answer || ""} />
        </ItemSectionContainer>

        <IconSectionContainer width="10%">
          <IconContainer>
            <Edit onClick={openEventEditor} className="h-4 w-4" />
          </IconContainer>
          <IconContainer>{/* <Copy className="h-4 w-4" /> */}</IconContainer>
          <IconContainer>
            {removingEvent ? (
              <Loader className="h-4 w-4" />
            ) : (
              <Trash2
                onClick={() => handleRemoveEvent(props.id)}
                className="h-4 w-4"
              />
            )}
          </IconContainer>
          {/* <IconContainer>
              <FileSymlink className="h-4 w-4" />
            </IconContainer> */}
        </IconSectionContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #d1d1d1;
  background: #fff;
`;

const ItemSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: center;
  text-align: center;
  gap: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconSectionContainer = styled.div<ItemSectionProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;
