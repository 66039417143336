import React, { useMemo } from "react";
import Select from "react-select";
import { Hosts, useSearchHostsQuery } from "src/generated/graphql";

type HostSelectionType = {
  value: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueSelect: any;
  filterHostId?: string;
};

export function HostSelection({ value, onValueSelect }: HostSelectionType) {
  // const { data: hostsData } = useSuspenseQuery<GetHostsQuery>(Get_Hosts, {
  //   variables: {
  //     where: {
  //       id: {
  //         _neq: filterHostId,
  //       },
  //     },
  //   },
  // });

  const { data: hostsData } = useSearchHostsQuery();

  const hosts = useMemo(() => {
    if (!hostsData) return [];
    const c: Hosts[] = JSON.parse(JSON.stringify(hostsData?.hosts));
    const sortedHosts = c.sort((a: Hosts, b: Hosts) =>
      (a.nickname || "").localeCompare(b.nickname || "", "en", {
        sensitivity: "base",
      })
    );
    return sortedHosts.map((sc: Hosts) => ({
      label: sc.nickname,
      value: sc.id,
    }));
  }, [hostsData]);

  const selectedValue = useMemo(() => {
    if (!hostsData) return [];
    const c: Hosts[] = JSON.parse(JSON.stringify(hostsData?.hosts));

    const selectedHost = c.find((i) => i.id === value);

    if (!selectedHost) {
      return null;
    }

    return { label: selectedHost?.nickname, value: selectedHost?.id };
  }, [hostsData, value]);

  // const onChange = (option: { label: string; value: string } | null) => {
  //   if (option) {
  //     onValueSelect("host_id", option.value, option.label);
  //   } else {
  //     onValueSelect("host_id", "");
  //   }
  // };

  return (
    <Select
      className="w-50"
      name="host"
      onChange={onValueSelect}
      options={hosts}
      placeholder="All hosts"
      value={selectedValue}
      isSearchable
      isClearable
    />
  );
}
