import React, { useState, useEffect, useRef } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Dropdown<T extends { [key: string]: any }>({
  items,
  selectedItem,
  setFilter,
  onSelect,
  idColumn = "",
  labelColumn = "",
}: {
  items: T[];
  selectedItem: T | undefined;
  setFilter: (filter: string) => void;
  onSelect: (item: T) => void;
  idColumn: string;
  labelColumn: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle closing dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Update server-side filter on search input change
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilter(search); // Update the filter on the server-side
    }, 300); // Debounce the request by 300ms

    return () => clearTimeout(delayDebounceFn); // Clear debounce timeout on unmount or input change
  }, [search, setFilter]);

  return (
    <div className="relative inline-block w-64" ref={dropdownRef}>
      <div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex w-full items-center rounded bg-gray-300 px-4 py-2 font-semibold text-gray-700 hover:bg-gray-400"
        >
          <span>{selectedItem ? selectedItem[labelColumn] : "Select One"}</span>
          <svg
            className="ml-auto h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            className="w-full rounded-t-md border border-gray-300 p-2 focus:outline-none"
          />
          <ul className="max-h-60 w-full overflow-auto rounded-b-md border border-gray-300">
            {items.length > 0 ? (
              items.map((item: T) => (
                <li
                  key={item[idColumn]}
                  onClick={() => {
                    onSelect(item);
                    setIsOpen(false);
                    setSearch(""); // Clear search after selection
                  }}
                  className="cursor-pointer p-2 hover:bg-gray-100"
                >
                  {item[labelColumn]}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500">No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
