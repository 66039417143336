import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { AlertTriangle, Pencil, Trash2 } from "lucide-react";
import React from "react";
import { Button, DataTable } from "../RadixWrapper";
import { EventInterface } from "src/types/Event";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useAppSelector } from "src/store";

type EventsListProps = {
  events: EventInterface[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
};

const columnHelper = createColumnHelper<EventInterface>();

const defaultColumns: ColumnDef<EventInterface, any>[] = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("name", {
    header: "Event Name",
    cell: function EventNameWrapper({ row, getValue }) {
      const eventId = row.getValue("id") as string;

      const { editingEvents } = useAppSelector((state) => {
        return {
          editingEvents: state.eventReducer.editingEvents,
        };
      });

      const isBeingEdited = editingEvents.some((eq) => eq.id === eventId);

      return (
        <div className="flex flex-col justify-center gap-2">
          {isBeingEdited && (
            <span className="inline-flex items-center gap-x-1.5 self-start rounded-md border border-rose-500 bg-rose-50 px-2 py-1 text-xs text-rose-600">
              <AlertTriangle className="h-4 w-4 text-rose-600" />
              editing . . .
            </span>
          )}
          <Link to={`/events/${eventId}/view`}>{getValue()}</Link>
        </div>
      );
    },
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.accessor("eventDateTime", {
    header: "Date / Time",
    cell: function EventDateTimeWrapper({ getValue }) {
      const dateTime = getValue() as string;

      return <span>{format(new Date(dateTime), "E, M/d/y")}</span>;
    },
  }),
  // ! See comment for # of Qs field below
  {
    id: "location",
    header: "Location",
    accessorFn: (originalRow: EventInterface) => {
      return originalRow.location?.name || "";
    },
    cell: ({ getValue }: { getValue: () => string }) => {
      const location = getValue();

      return <span>{location}</span>;
    },
  },
  columnHelper.accessor("cost", {
    header: "Cost",
    cell: ({ getValue }) => {
      const cost = getValue() as string;

      return <span>${cost}</span>;
    },
  }),
  columnHelper.accessor("account_id", {
    header: "Hosts",
    cell: function EventLocationWrapper() {
      return <span></span>;
    },
  }),
  /**
   * ! From https://tanstack.com/table/latest/docs/guide/column-defs#column-helpers
   * * 🧠 Remember, the accessed value is what is used to sort, filter, etc. so you'll want to make sure your accessor
   * * function returns a primitive value that can be manipulated in a meaningful way. If you return a non-primitive
   * * value like an object or array, you will need the appropriate filter/sort/grouping functions to manipulate them,
   * * or even supply your own! 😬
   * ! What this means is that in columnHelper.accessor("attribute", {...config}), the "attribute" used should be a
   * ! primitive type (number, string etc). It cannot be an array or object. If it is so, then a very lengthy error
   * ! about "accessorFn" will be thrown. Hence, below, instead of using columnHelper.accessor() I have defined
   * ! the ColumnDef object (which columnHelper.accessor() generates for us)
   * ! One thing I could not resolve though is that number is a primitve type, it allowed only string. Not sure
   * ! which type definition has gone rogue.
   */
  {
    id: "questionCount",
    header: "# of Qs",
    accessorFn: (originalRow: EventInterface) => {
      return String(
        originalRow.eventQuestions_aggregate?.aggregate?.count || 0
      );
    },
    cell: ({ getValue }: { getValue: () => string }) => {
      const count = getValue();

      return (
        <span>
          {count || 0} {count === "1" ? "question" : "questions"}
        </span>
      );
    },
  },
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 100,
    cell: function ActionCell({ row, table }) {
      const eventId = row.getValue("id") as string;
      const eventName = row.getValue("name") as string;
      const meta = table.options.meta;

      return (
        <div className="flex gap-2" key="read-mode">
          <Button variant="ghost" size="icon" asChild>
            <Link to={`/events/${eventId}/edit`}>
              <Pencil className="h-4 w-4" />
            </Link>
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();

              meta?.onDeleteRow(eventId, eventName);
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      );
    },
  }),
];

export function EventList({
  events,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
}: EventsListProps) {
  return (
    <DataTable
      columns={defaultColumns}
      data={events ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      isRowDraggable={true}
      showToggleVisibility={true}
    />
  );
}
