/**
 * ! DO NOT USE Sheets component here. I faced the following issues:
 * ! - If I add Select component in the form, the sheet would open, then close and then open again
 * ! - I could not disable event propagation if I clicked on any of the action column buttons (Note that
 * !   the entire row in Question table also has a click handler on it - both would trigger). This is how
 * !   Portals behave apparently, but event.stopPropogation() did not help
 */
import React, { Suspense, useMemo, useState } from "react";
import clsx from "clsx";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from "@apollo/client";
import * as z from "zod";
import {
  Create_Question_Mutation,
  Get_Categories,
  Get_QuestionTypes,
  Update_Question_Mutation,
} from "src/gql";
import {
  Badge,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "src/components/RadixWrapper";
import { Sparkles, XCircle } from "lucide-react";
import {
  GetCategoriesQuery,
  GetQuestionTypesQuery,
  InsertTagsMutationMutation,
  SearchTagsQuery,
  useSuggestTagsLazyQuery,
} from "src/generated/graphql";
import { QuestionsInterface, TagInterface } from "src/types/Questions";
import { useToast } from "../RadixWrapper/UseToast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePubSubInstance } from "src/store";
import { INSERT_TAGS_MUTATION, SEARCH_TAGS_QUERY } from "src/gql/tags";
import ReactSelect, { MultiValue } from "react-select";
import {
  Delete_Question_Tags_Mutation,
  Insert_Question_Tags_Mutation,
} from "src/gql/question-tags";
import { useAuth } from "src/hooks/useAuth";

const QUESTION_STATUS_DRAFT = "DRAFT";
const QUESTION_STATUS_ACTIVE = "ACTIVE";
const QUESTION_STATUS_RETIRED = "RETIRED";

const QUESTION_STATUS_OPTIONS = [
  { value: QUESTION_STATUS_DRAFT, label: "Draft" },
  { value: QUESTION_STATUS_ACTIVE, label: "Active" },
  { value: QUESTION_STATUS_RETIRED, label: "Retired" },
];

const DEFAULT_QUESTION_TYPE_ID = "5c500b5a-519d-4ba6-a3b3-52d32b1e2dc5";

const formSchema = z.object({
  categoryId: z.string(),
  type_id: z.string().min(1, { message: "Select a type" }),
  question: z.string().min(1),
  answer: z.string(),
  status: z.string(),
  tags: z.object({ id: z.string(), name: z.string() }).array(),
});

type CreateQuestionFormType = {
  showQuestion: boolean;
  toggleShowQuestion: () => void;
  question?: QuestionsInterface;
};

type QuestionTagSelectType = {
  values: TagInterface[];
  onValuesSelect: (values: TagInterface[]) => void;
};

type ReactSelectOptionType = {
  value: string;
  label: string;
};

// Given two string arrays, returns an array of two items
// First item contains strings that don't exist in first input array
// Second item contains strings that don't exist in second input array
function diffTags(tagOneIds: string[], tagTwoIds: string[]) {
  const toAdd = tagTwoIds.filter((t) => !tagOneIds.includes(t));
  const toRemove = tagOneIds.filter((t) => !tagTwoIds.includes(t));

  return [toAdd, toRemove];
}

// Given two arrays - an array of strings, and the other array of objects of type { id: string, name: string}
// This function returns an array of two items
// First item in the array is the second input array itself (w/ just the ids).
// Second item in the array is the strings that exist in first input array, but not in
// the second input array (name field comparison)
function seggregateTags(stringArray: string[], objectArray: TagInterface[]) {
  const setOfExistingTagNames = new Set(objectArray.map((obj) => obj.name));

  const exists: TagInterface[] = [];
  const notExists: string[] = [];

  stringArray.forEach((sa) => {
    if (setOfExistingTagNames.has(sa)) {
      const existingTag = objectArray.find((obj) => obj.name === sa);

      if (existingTag?.id) {
        exists.push(existingTag);
      }
    } else {
      notExists.push(sa);
    }
  });

  return { existingSuggestedTags: exists, notExistingSuggestedTags: notExists };
}

export function QuestionTagSelect({
  values,
  onValuesSelect,
}: QuestionTagSelectType) {
  const { data: tagsData } =
    useSuspenseQuery<SearchTagsQuery>(SEARCH_TAGS_QUERY);

  const tags = useMemo(() => {
    const ts: typeof tagsData.tags = JSON.parse(JSON.stringify(tagsData.tags));
    const sortedTags = ts.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base" })
    );
    return sortedTags.map((st) => ({ label: st.name, value: st.id }));
  }, [tagsData]);

  const selectedValues = useMemo(() => {
    const ts: typeof tagsData.tags = JSON.parse(JSON.stringify(tagsData.tags));

    const idsSet = new Set(values.map((t) => t.id));

    const v = ts
      .filter((t) => idsSet.has(t.id))
      .map((t) => ({ label: t.name, value: t.id }));

    return v;
  }, [tagsData, values]);

  const onChange = (newValue: MultiValue<ReactSelectOptionType>) => {
    const selectedTags = newValue
      ? newValue.map((nv) => ({
          id: nv.value,
          name: nv.label,
        }))
      : [];

    onValuesSelect(selectedTags);
  };

  return (
    <ReactSelect
      className="w-50"
      name="tags"
      onChange={onChange}
      options={tags}
      placeholder="Select tag(s)"
      value={selectedValues}
      closeMenuOnSelect={false}
      menuPlacement="top"
      isSearchable
      isClearable
      isMulti
    />
  );
}

export function CreateEditQuestion({
  showQuestion,
  toggleShowQuestion,
  question,
}: CreateQuestionFormType) {
  const [selectedSuggestedTags, setSelectedSuggestedTags] = useState<string[]>(
    []
  );
  const { user } = useAuth();
  const { toast } = useToast();
  const apolloClient = useApolloClient();
  const { data: categoriesData } = useQuery<GetCategoriesQuery>(Get_Categories);
  const { data: questionTypesData } =
    useQuery<GetQuestionTypesQuery>(Get_QuestionTypes);
  const [createQuestionMutation] = useMutation(Create_Question_Mutation);
  const [updateQuestion] = useMutation(Update_Question_Mutation);
  const [insertTags] = useMutation(Insert_Question_Tags_Mutation);
  const [removeTags] = useMutation(Delete_Question_Tags_Mutation);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: question
      ? {
          categoryId: question.category_id,
          type_id: question.type_id,
          question: question.question,
          answer: question.answer,
          status: question.status,
          tags: question.questionTags?.map((qt) => qt.tag) || [],
        }
      : {
          categoryId: "",
          type_id: DEFAULT_QUESTION_TYPE_ID,
          question: "",
          answer: "",
          status: QUESTION_STATUS_DRAFT,
          tags: [],
        },
  });

  const watchQuestion = form.watch("question");

  const [suggestTags, { loading: loadingTagSuggestions, data: suggestedTags }] =
    useSuggestTagsLazyQuery();

  const { pubSubInstance } = usePubSubInstance();

  const categories = useMemo(() => {
    if (categoriesData) {
      const c: typeof categoriesData.categories = JSON.parse(
        JSON.stringify(categoriesData.categories)
      );
      return c.sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
    }

    return [];
  }, [categoriesData]);

  const questionTypes = useMemo(() => {
    if (questionTypesData) {
      const c: typeof questionTypesData.questionTypes = JSON.parse(
        JSON.stringify(questionTypesData.questionTypes)
      );
      return c.sort((a, b) =>
        a.name.localeCompare(b.name, "en", { sensitivity: "base" })
      );
    }

    return [];
  }, [questionTypesData]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    toggleShowQuestion();

    toast({
      title: question
        ? "Updating the question with the following values:"
        : "Creating a question with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      ),
    });

    try {
      if (question) {
        // Determine changes to tags
        const tagIdsOnQuestionDuringSave = new Set<string>();

        // First - has the user selected any suggested tags?
        if (selectedSuggestedTags.length > 0) {
          // Search if these suggested tags already exist (as tags - checking if they exist as tags on the question is the next step - we need the id for that, and hence this step)
          const searchedTags = await apolloClient.query<SearchTagsQuery>({
            query: SEARCH_TAGS_QUERY,
            variables: {
              where: {
                _or: selectedSuggestedTags.map((s) => ({
                  name: {
                    _eq: s,
                  },
                })),
              },
            },
          });

          // Identify which tags exist and which dont
          const { existingSuggestedTags, notExistingSuggestedTags } =
            seggregateTags(selectedSuggestedTags, searchedTags.data.tags);

          existingSuggestedTags.forEach((est) => {
            if (est.id) {
              if (!tagIdsOnQuestionDuringSave.has(est.id)) {
                tagIdsOnQuestionDuringSave.add(est.id);
              }
            }
          });

          if (notExistingSuggestedTags.length > 0) {
            // Let's create the suggested tags that do not exist
            // We need to create the tags first before we can add them to the question
            const newlyCreatedTags =
              await apolloClient.mutate<InsertTagsMutationMutation>({
                mutation: INSERT_TAGS_MUTATION,
                variables: {
                  objects: notExistingSuggestedTags.map((t) => ({ name: t })),
                },
                refetchQueries: [SEARCH_TAGS_QUERY],
              });

            newlyCreatedTags.data?.insert_tags?.returning.forEach((it) => {
              if (!tagIdsOnQuestionDuringSave.has(it.id)) {
                tagIdsOnQuestionDuringSave.add(it.id);
              }
            });
          }
        }

        values.tags.forEach((vt) => {
          if (!tagIdsOnQuestionDuringSave.has(vt.id)) {
            tagIdsOnQuestionDuringSave.add(vt.id);
          }
        });

        let existingTags: string[];

        if (question.questionTags) {
          existingTags = question.questionTags
            .map((qt) => qt.tag?.id)
            .filter((t) => !!t) as string[];
        } else {
          existingTags = [];
        }
        const [tagsToAdd, tagsToRemove] = diffTags(
          existingTags,
          Array.from(tagIdsOnQuestionDuringSave)
        );

        if (tagsToAdd.length > 0) {
          await insertTags({
            variables: {
              objects: tagsToAdd.map((t) => ({
                org_id: user?.orgId,
                question_id: question.id,
                tag_id: t,
              })),
            },
          });
        }

        if (tagsToRemove.length > 0) {
          // Tags to remove is the tag id. We need the questionTag id associated with that tag and question
          const questionTagsToRemove = tagsToRemove
            .map(
              (t) => question.questionTags?.find((qt) => qt.tag?.id === t)?.id
            )
            .filter((t) => !!t) as string[];
          await removeTags({
            variables: {
              where: {
                id: {
                  _in: questionTagsToRemove,
                },
              },
            },
          });
        }

        const { data } = await updateQuestion({
          variables: {
            pk_columns: { id: question.id },
            _set: {
              answer: values.answer,
              question: values.question,
              category_id: values.categoryId,
              type_id: values.type_id,
              status: values.status,
            },
          },
        });

        pubSubInstance?.publish({
          channel: "text_channel",
          message: {
            question: data.update_questions_by_pk,
            type: "question_updated",
          },
        });
      } else {
        await createQuestionMutation({
          variables: {
            object: {
              answer: values.answer,
              question: values.question,
              category_id: values.categoryId,
              type_id: values.type_id,
              status: values.status,
            },
          },
        });
      }

      toast({
        title: "SUCCESS",
        description: question
          ? "Question updated successfully"
          : "New question created successfully",
      });

      form.reset();
    } catch (err) {
      if (question) {
        console.log("An error occurred during question update");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not update the question. Try again.`,
        });
      } else {
        console.log("An error occurred during question creation");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not create the question. Try again.`,
        });
      }
    }
  };

  const onSuggestedTagSelectedToggle = (suggestedTag: string) => {
    if (selectedSuggestedTags.includes(suggestedTag)) {
      setSelectedSuggestedTags((prev) =>
        prev.filter((p) => p !== suggestedTag)
      );
    } else {
      setSelectedSuggestedTags((prev) => [...prev, suggestedTag]);
    }
  };

  return (
    <div
      className={clsx({
        "fixed inset-0 z-10 h-full w-full": true,
        invisible: !showQuestion,
      })}
    >
      <div
        onClick={toggleShowQuestion}
        className={clsx({
          "absolute inset-0 h-full w-full bg-gray-900": true,
          "transition-all duration-500 ease-out": true,
          "opacity-0": !showQuestion,
          "opacity-50": showQuestion,
        })}
      ></div>
      <div
        className={clsx({
          "absolute right-0 h-full w-96 overflow-auto bg-white": true,
          "transition-all duration-300 ease-out": true,
          "translate-x-full": !showQuestion,
        })}
      >
        <div className="flex flex-col space-y-2 px-6 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-foreground">
              {question ? "Edit Question" : "Create Question"}
            </h2>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none"
              onClick={toggleShowQuestion}
            >
              <XCircle className="h-4 w-4" />
            </Button>
          </div>
          <p className="text-sm text-muted-foreground">
            Here you can{" "}
            {question ? "edit an existing question" : "compose a new question"}{" "}
            which you can later add to a notebook
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="categoryId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Question Category</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose one" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {categories.map((c) => (
                        <SelectItem key={c.id} value={c.id}>
                          {c.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Question Type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose one" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {questionTypes.map((c) => (
                        <SelectItem key={c.id} value={c.id}>
                          {c.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="question"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="required">Question Body</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="eg. What is Jeff Bezos's middlename?"
                      rows={4}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="answer"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Answer</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Stuart" rows={4} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Choose one" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {QUESTION_STATUS_OPTIONS.map((c) => (
                        <SelectItem key={c.value} value={c.value}>
                          {c.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {question && (
              <FormField
                control={form.control}
                name="tags"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <div className="flex items-center justify-between">
                        <FormLabel>Tags</FormLabel>
                        <Button
                          type="button"
                          title="Use AI to suggest tags"
                          variant="ghost"
                          size="icon"
                          className={clsx({
                            "animate-pulse": loadingTagSuggestions,
                          })}
                          onClick={() =>
                            watchQuestion.length > 0 &&
                            suggestTags({
                              variables: { question: watchQuestion },
                            })
                          }
                          disabled={watchQuestion.length === 0}
                        >
                          <Sparkles className="h-4 w-4" />
                        </Button>
                      </div>
                      <Suspense fallback={<>Loading...</>}>
                        <QuestionTagSelect
                          values={field.value}
                          onValuesSelect={field.onChange}
                        />
                      </Suspense>
                      <div className="text-[0.8rem] text-muted-foreground">
                        {loadingTagSuggestions && (
                          <p>Loading tag suggestions...</p>
                        )}
                        {suggestedTags && (
                          <>
                            <p>
                              Here are the suggested tags{" "}
                              {selectedSuggestedTags.length > 0 && (
                                <span>
                                  ({selectedSuggestedTags.length} selected)
                                </span>
                              )}
                            </p>
                            <div className="mt-4 flex flex-wrap items-center gap-2">
                              {suggestedTags.suggestQuestionTags?.tags?.map(
                                (s) => (
                                  <Badge
                                    key={s}
                                    variant="outline"
                                    className={clsx({
                                      "hover:cursor-pointer hover:bg-secondary":
                                        true,
                                      "bg-secondary":
                                        selectedSuggestedTags.includes(
                                          s as string
                                        ),
                                    })}
                                    onClick={() =>
                                      onSuggestedTagSelectedToggle(s as string)
                                    }
                                  >
                                    {s}
                                  </Badge>
                                )
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            )}

            <Button type="submit">
              {question ? "Save" : "Create Question"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
