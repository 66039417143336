import React from "react";
import { EventQuestions } from "src/generated/graphql";
import styled from "styled-components";
// import { useAppDispatch } from "../../store";
import { LoadingAnimation, Paragraph } from "../Common";
import { EventQuestionListItem } from "./EventQuestionListItem";

interface HeaderItemProps {
  width?: string;
}
interface EventQuestionListProps {
  questions: EventQuestions[];
}

export const EventQuestionList: React.FC<EventQuestionListProps> = ({
  questions,
}: EventQuestionListProps) => {
  // const { pubSubInstance } = usePubSubInstance();
  // const { events } = useAppSelector((state) => {
  //   return {
  //     events: state.eventReducer.events,
  //   };
  // });

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const listener = {
  //     status: (statusEvent: Pubnub.StatusEvent) => {
  //       if (statusEvent.category === "PNConnectedCategory") {
  //         console.log("Connected");
  //       }
  //     },
  //     message: async (event: Pubnub.MessageEvent) => {
  //       if (event.message.type === "editing_event_form") {
  //         dispatch(
  //           triggerEditEvent({
  //             isEditing: true,
  //             event: event.message.event,
  //           })
  //         );
  //       }

  //       if (event.message.type === "editing_event_canceled") {
  //         dispatch(editingEventCanceled({ id: event?.message?.event?.id }));
  //       }

  //       if (event.message.type === "event_updated") {
  //         dispatch(editingEventCanceled({ id: event?.message?.event?.id }));

  //         dispatch(setEvents({ events: event.message.events }));
  //       }
  //     },
  //   };

  //   pubSubInstance?.addListener(listener);

  //   pubSubInstance?.subscribe({
  //     withPresence: true,
  //     channels: ["text_channel"],
  //   });

  //   return () => {
  //     pubSubInstance?.removeListener(listener);
  //     pubSubInstance?.unsubscribeAll();
  //   };
  // }, [pubSubInstance, dispatch]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderItemContainer width="10%">
          <Paragraph fontSize="14px" fontWeight="600" text="#" />
        </HeaderItemContainer>

        <HeaderItemContainer width="50%">
          <Paragraph fontSize="14px" fontWeight="600" text="Question" />{" "}
        </HeaderItemContainer>

        <HeaderItemContainer width="30%">
          <Paragraph fontSize="14px" fontWeight="600" text="Answer" />
        </HeaderItemContainer>
      </HeaderContainer>
      <QuestionsSection>
        {questions ? (
          questions.map((item) => (
            <EventQuestionListItem {...item} key={item.id} />
          ))
        ) : (
          <LoadingAnimation />
        )}
      </QuestionsSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 20px 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding-bottom: 16px;
  border-bottom: 1px solid #d1d1d1;
`;
const HeaderItemContainer = styled.div<HeaderItemProps>`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QuestionsSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
