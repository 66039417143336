import React from "react";
import styled from "styled-components";

interface ParagraphProps {
  text?: string;
  color?: string;
  cursor?: string;
  fontSize?: string;
  fontFamily?: string;
  fontStyle?: string;
  fontWeight?: string;
  lineHeight?: string;
  decoration?: string;
  padding?: string;
  background?: string;
  borderradius?: string;
  width?: string;
  height?: string;
  textOverflow?: string;
  whiteSpace?: string;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

export const Paragraph = (props: ParagraphProps) => {
  return (
    <StyledParagraph
      color={props.color}
      fontSize={props.fontSize}
      fontFamily={props.fontFamily}
      fontStyle={props.fontStyle}
      width={props.width}
      height={props.height}
      fontWeight={props.fontWeight}
      lineHeight={props.lineHeight}
      decoration={props.decoration}
      onClick={props.onClick}
      id={props.id}
      borderradius={props.borderradius}
      background={props.background}
      padding={props.padding}
      textOverflow={props?.textOverflow}
      whiteSpace={props?.whiteSpace}
      cursor={props.cursor}
    >
      {props.text}
    </StyledParagraph>
  );
};

const StyledParagraph = styled.p<ParagraphProps>`
  cursor: ${({ cursor }) => (cursor ? cursor : "arrow")};
  color: ${({ color }) => (color ? color : "#000")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  background: ${({ background }) => (background ? background : "14px")};
  border-radius: ${({ borderradius }) => (borderradius ? borderradius : "0px")};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : "Inter")};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : "normal")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "normal")};
  padding: ${({ padding }) => (padding ? padding : "0px")};
  text-decoration: ${({ decoration }) => (decoration ? decoration : "initial")};
  width: ${({ width }) => (width ? width : "initial")};
  height: ${({ height }) => (height ? height : "initial")};
  text-overflow: ${({ textOverflow }) =>
    textOverflow ? textOverflow : "initial"};
  white-space:: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : "initial")};
`;
