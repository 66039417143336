import { gql } from "@apollo/client";
import {
  questions_bool_exp,
  notebooks_bool_exp,
  uuid_comparison_exp,
  Int_comparison_exp,
} from "./queries";
// import { Regions_Set_Input } from "src/generated/graphql";

export interface questions_pk_columns_input {
  id: string;
}

export interface notebooks_pk_columns_input {
  id: string;
}

export interface questions_set_input {
  answer?: string;
  question?: string;
  category_id: string;
}

export interface notebookQuestions_insert_input {
  notebook_id: string;
  question_id: string;
  org_id: string;
  sortOrder: number;
}

export interface locations_insert_input {
  name: string;
  address?: string;
  city: string;
  state: string;
  zip: string;
}

export interface accounts_insert_input {
  name: string;
  region_id: string;
}

export interface events_insert_input {
  org_id?: string;
  name?: string;
  location_id?: string;
  account_id?: string;
  eventDateTime?: string;
}

export interface events_set_input {
  org_id?: string;
  name?: string;
  location_id?: string;
  account_id?: string;
  region_id?: string;
  eventDateTime?: string;
}

export interface notebookQuestions_set_input {
  sortOrder: number;
}

export interface notebooks_set_input {
  org_id?: string;
  owner_id?: string;
  name?: string;
  isArchived?: boolean;
  isStarred?: boolean;
}

export interface notebookQuestions_bool_exp {
  id?: uuid_comparison_exp;
  notebookQuestions_notebook?: notebooks_bool_exp;
  notebookQuestions_question?: questions_bool_exp;
  notebook_id?: uuid_comparison_exp;
  org_id?: uuid_comparison_exp;
  question_id?: uuid_comparison_exp;
  sortOrder?: Int_comparison_exp;
}

export interface notebookQuestions_updates {
  where: notebookQuestions_bool_exp;
  _set: notebookQuestions_set_input;
}

export interface locations_pk_columns_input {
  id: string;
}

export interface accounts_pk_columns_input {
  id: string;
}

export interface locations_set_input {
  name: string;
  address?: string;
  city: string;
  state: string;
  zip: string;
}

export interface accounts_set_input {
  name: string;
}

export interface questions_insert_input {
  question: string;
  answer: string;
  category_id: string;
}

export interface notebooks_insert_input {
  name: string;
  // owner_id?: string;
}

export interface users_pk_columns_input {
  id: string;
}

export interface users_set_input {
  settings: Record<string, unknown>;
}

export interface eventQuestions_insert_input {
  question_id: string;
  event_id: string;
  notes: string;
  sortOrder: number;
}

export const Create_Question_Mutation = gql`
  mutation Insert_Question($object: questions_insert_input!) {
    insert_questions_one(object: $object) {
      id
      question
      answer
    }
  }
`;

export const Create_Location_Mutation = gql`
  mutation Insert_Location($object: locations_insert_input!) {
    insert_locations_one(object: $object) {
      id
      name
    }
  }
`;

export const Update_Question_Mutation = gql`
  mutation update_questions_by_pk(
    $pk_columns: questions_pk_columns_input!
    $_set: questions_set_input!
  ) {
    update_questions_by_pk(pk_columns: $pk_columns, _set: $_set) {
      question
      answer
      org_id
      category {
        name
        id
      }
      category_id
      created_at
      id
      isTimeSensitive
      updated_at
      questionType {
        name
        id
      }
      questionTags {
        id
        tag {
          id
          name
        }
      }
      type_id
      status
    }
  }
`;

export const Create_Note_Mutation = gql`
  mutation insert_notebooks_one($object: notebooks_insert_input!) {
    insert_notebooks_one(object: $object) {
      id
      owner_id
      name
      notebooks_notebookQuestions {
        id
        question_id
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      created_at
      updated_at
    }
  }
`;

export const Update_NoteBook_Mutation = gql`
  mutation update_notebooks_by_pk(
    $pk_columns: notebooks_pk_columns_input!
    $_set: notebooks_set_input!
  ) {
    update_notebooks_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions {
        id
        question_id
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      created_at
      updated_at
      isStarred
    }
  }
`;

export const Update_Many_NoteBook_Questions_Mutation = gql`
  mutation update_notebookQuestions_many(
    $updates: [notebookQuestions_updates!]!
  ) {
    update_notebookQuestions_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const Insert_NoteBook_Question_Mutation = gql`
  mutation insert_notebookQuestions_one(
    $object: notebookQuestions_insert_input!
  ) {
    insert_notebookQuestions_one(object: $object) {
      id
      question_id
      sortOrder
      notebookQuestions_question {
        answer
        question
        id
        created_at
        updated_at
        category {
          name
          id
        }
      }
    }
  }
`;

export const Delete_NoteBook_Question_Mutation = gql`
  mutation delete_notebookQuestions_by_pk($id: uuid!) {
    delete_notebookQuestions_by_pk(id: $id) {
      id
    }
  }
`;

export const Update_Location_Mutation = gql`
  mutation update_locations_by_pk(
    $pk_columns: locations_pk_columns_input!
    $_set: locations_set_input!
  ) {
    update_locations_by_pk(pk_columns: $pk_columns, _set: $_set) {
      name
      address
      city
      state
      zip
      id
    }
  }
`;

export const Delete_Location_Mutation = gql`
  mutation delete_locations_by_pk($id: uuid!) {
    delete_locations_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const Create_Event_Mutation = gql`
  mutation insert_events_one($object: events_insert_input!) {
    insert_events_one(object: $object) {
      id
      account {
        id
        name
        region_id
      }
      name
      org_id
      eventDateTime
      location {
        city
        org_id
        state
      }
      location_id
    }
  }
`;

export const Update_Event_Mutation = gql`
  mutation update_events_by_pk(
    $pk_columns: events_pk_columns_input!
    $_set: events_set_input!
  ) {
    update_events_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      account_id
      eventDateTime
      eventQuestions_aggregate {
        aggregate {
          count
        }
      }
      location_id
      location {
        id
        city
        name
        state
      }
      name
      org_id
      status
    }
  }
`;

export const Delete_Event_Mutation = gql`
  mutation delete_events_by_pk($id: uuid!) {
    delete_events_by_pk(id: $id) {
      id
      id
      name
      org_id
      eventDateTime
    }
  }
`;

export const Create_Account_Mutation = gql`
  mutation Insert_Account($object: accounts_insert_input!) {
    insert_accounts_one(object: $object) {
      id
      name
    }
  }
`;

export const Update_Account_Mutation = gql`
  mutation update_accounts_by_pk(
    $pk_columns: accounts_pk_columns_input!
    $_set: accounts_set_input!
  ) {
    update_accounts_by_pk(pk_columns: $pk_columns, _set: $_set) {
      name
      id
    }
  }
`;

export const Delete_Account_Mutation = gql`
  mutation delete_accounts_by_pk($id: uuid!) {
    delete_accounts_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const Delete_Event_Questions = gql`
  mutation DeleteEventQuestions($where: eventQuestions_bool_exp!) {
    delete_eventQuestions(where: $where) {
      affected_rows
    }
  }
`;

export const Create_Event_Questions = gql`
  mutation MyMutation($objects: [eventQuestions_insert_input!]!) {
    insert_eventQuestions(objects: $objects) {
      affected_rows
    }
  }
`;

export const Update_User = gql`
  mutation UpdateUser(
    $pk_columns: users_pk_columns_input!
    $_set: users_set_input
  ) {
    update_users_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      email
      settings
    }
  }
`;
