import { useMutation } from "@apollo/client";
import { Edit, Loader, Trash2 } from "lucide-react";
import { FC, default as React, useState } from "react";
import { EventQuestions } from "src/generated/graphql";
import { Delete_Event_Mutation } from "../../gql";
import {
  setEvents,
  useAppDispatch,
  useAppSelector,
  usePubSubInstance,
} from "../../store";
import { removeArrayItem } from "../helpers";

export interface ListItemProps extends EventQuestions {}

export const EventQuestionListItem: FC<ListItemProps> = (props) => {
  const dispatch = useAppDispatch();
  const [, setOpenEditor] = useState(false);
  const [deleteEventMutation, { loading: removingEvent }] = useMutation(
    Delete_Event_Mutation
  );
  const { pubSubInstance } = usePubSubInstance();

  const { events } = useAppSelector((state) => {
    return {
      events: state.eventReducer.events,
    };
  });

  // const handleCancel = () => {
  //   setOpenEditor(false);
  //   pubSubInstance &&
  //     pubSubInstance.publish({
  //       channel: "text_channel",
  //       message: { question: props, type: "editing_canceled" },
  //     });
  // };

  // const { data: categoriesData } = useQuery(Get_Categories);

  // const handleSubmitForm = () => {
  //   setOpenEditor(false);
  // };

  const handleRemoveEvent = async (itemId: string) => {
    const removeEvent = await deleteEventMutation({
      variables: {
        id: itemId,
      },
    });

    const updatedEvents = removeArrayItem(
      events,
      removeEvent.data.delete_events_by_pk
    );

    dispatch(setEvents({ events: updatedEvents }));

    pubSubInstance &&
      pubSubInstance.publish({
        channel: "text_channel",
        message: { events: updatedEvents, type: "event_updated" },
      });
  };

  const openEventEditor = async (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    setOpenEditor(true);

    (await pubSubInstance) &&
      pubSubInstance?.publish({
        channel: "text_channel",
        message: { event: props, type: "editing_event_form" },
      });
  };

  // const questionFormProps = {
  //   categories:
  //     categoriesData &&
  //     categoriesData.categories.map((item: CategoryInterface) => {
  //       return { name: item.name, id: item.id };
  //     }),
  // };

  // const getText = (count: number | undefined) => {
  //   if (typeof count === "number") {
  //     if (count === 1) {
  //       return "1 question";
  //     }

  //     return `${count} questions`;
  //   }

  //   return "";
  // };
  return (
    <>
      <div className="flex w-full items-center border-b border-gray-300 bg-white py-2">
        <div className="w-1/10 flex items-center gap-1 text-center">
          <div>{props.sortOrder}</div>
        </div>
        <div className="flex w-1/2 items-center gap-1 text-center">
          <div>{props?.question?.question}</div>
        </div>
        <div className="w-3/10 flex items-center gap-1 text-center">
          <div>{props.question?.answer}</div>
        </div>

        <div className="w-1/10 flex justify-between">
          <div className="cursor-pointer">
            <Edit onClick={openEventEditor} className="h-4 w-4" />
          </div>
          <div className="cursor-pointer">
            {/* <Copy className="h-4 w-4" /> */}
          </div>
          <div className="cursor-pointer">
            {removingEvent ? (
              <Loader className="h-4 w-4" />
            ) : (
              <Trash2
                onClick={() => handleRemoveEvent(props.id)}
                className="h-4 w-4"
              />
            )}
          </div>
          {/* <div className="cursor-pointer">
              <FileSymlink className="h-4 w-4" />
            </div> */}
        </div>
      </div>
    </>
  );
};
