import React from "react";
import styled from "styled-components";
import { Paragraph } from "./Paragraph";
import { AlertCircle } from "lucide-react";

interface ErrorInterface {
  message: string;
}

export const ErrorMessage = (props: ErrorInterface) => {
  return props.message ? (
    <Container className="flex flex-row">
      <div className="basis-2/12">
        <AlertCircle className="h-4 w-4" />
      </div>
      <div className="basis-7/12">
        <Paragraph fontSize="12px" color="red" text={props.message} />
      </div>
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 12px;
`;
