import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { CalendarDays, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import SearchableSelect from "src/components/Events/components/SearchableSelect";
import FormFieldSkeleton from "src/components/Events/components/Skeleton";
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from "src/components/RadixWrapper";
import { useToast } from "src/components/RadixWrapper/UseToast";
import {
  useGetAccountsQuery,
  useGetEventByIdQuery,
  useGetLocationsQuery,
  useGetRegionsQuery,
} from "src/generated/graphql";
import { Create_Event_Mutation, Update_Event_Mutation } from "src/gql";
import { useAuth } from "src/hooks/useAuth";
import { cn } from "src/lib/utils";
import { usePubSubInstance } from "src/store";
import { z } from "zod";
import HostEditTable from "./HostEditTable";

type CreateEditEventType = {
  eventId?: string;
  onCreateAccount: () => void;
  onCreateLocation: () => void;
};

const ITEMS_PER_PAGE = 10;

const formSchema = z.object({
  name: z.string().min(1, { message: "Event name is required" }),
  date: z.date(),
  accountId: z.string().min(1, { message: "An account is required" }),
  locationId: z.string().min(1, { message: "A location is required" }),
  regionId: z.string().min(1, { message: "A region is required" }),

  publicNotes: z.string().optional(),
  hostNotes: z.string().optional(),
  adminNotes: z.string().optional(),

  cost: z.number().min(0, { message: "Price must be a valid number" }),
});

type Host = {
  name: string;
  cost: number;
};

export function CreateEditEvent({
  eventId,
  onCreateAccount,
  onCreateLocation,
}: CreateEditEventType) {
  const [createEventMutation, { loading: creatingEvent }] = useMutation(
    Create_Event_Mutation
  );
  const [updateEventMutation, { loading: updatingEvent }] = useMutation(
    Update_Event_Mutation
  );
  const [searchAccountName, setSearchAccountName] = useState("");
  const [searchLocationName, setSearchLocationName] = useState("");
  const [searchRegionName, setSearchRegionName] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [hosts, setHosts] = useState([
    { name: "Mark Bernacki", role: "Host", cost: 100.0 } as Host,
    { name: "James Cori", role: "Co-Host", cost: 100.0 } as Host,
    { name: "Eddie Reyes", role: "Training", cost: 50.0 } as Host,
    { name: "Frank Schlafer", role: "Spectator", cost: 0.0 } as Host,
    {
      name: "Valerie Bernacki",
      role: "Scoring Assistant",
      cost: 50.0,
    } as Host,
    // Add more host objects as needed
  ]);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { pubSubInstance } = usePubSubInstance();

  const { data: eventData, loading: loadingEvent } = useGetEventByIdQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: eventId!,
    },
    fetchPolicy: "network-only",
    skip: !eventId,
  });

  const { data: accountsData, loading: loadingAccounts } = useGetAccountsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${searchAccountName}%`,
        },
      },
    },
    skip: !searchAccountName.length,
    fetchPolicy: "network-only",
  });

  const { data: regionsData, loading: loadingRegions } = useGetRegionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
      where: {
        name: {
          _ilike: `%${searchRegionName}%`,
        },
      },
    },
    skip: !searchAccountName.length,
    fetchPolicy: "network-only",
  });

  const { data: locationsData, loading: loadingLocations } =
    useGetLocationsQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: ITEMS_PER_PAGE,
        offset: 0,
        where: {
          name: {
            _ilike: `%${searchLocationName}%`,
          },
        },
      },
      skip: !searchLocationName.length,
      fetchPolicy: "network-only",
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      date: new Date(),
      accountId: "",
      locationId: "",
      regionId: "",
      cost: 0,
    },
  });

  // Populate form fields for editing an event
  useEffect(() => {
    const event = eventData?.events_by_pk;

    if (event) {
      form.reset({
        name: event.name,
        date: new Date(event.eventDateTime),
        accountId: event.account?.id,
        locationId: event.location?.id,
        regionId: event.region?.id,
        cost: Number(event.cost) || 0,
      });
    }
  }, [eventData, form]);

  // Notify edit and its cancellation
  useEffect(() => {
    if (eventData?.events_by_pk?.id) {
      pubSubInstance?.publish({
        channel: "text_channel",
        message: { event: eventData.events_by_pk, type: "editing_event_form" },
      });

      return () => {
        pubSubInstance?.publish({
          channel: "text_channel",
          message: {
            event: eventData.events_by_pk,
            type: "editing_event_canceled",
          },
        });
      };
    }
  }, [pubSubInstance, eventData]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (eventId) {
      toast({
        description: "Updating event...",
      });

      const { data } = await updateEventMutation({
        variables: {
          pk_columns: { id: eventId },
          _set: {
            region_id: values.regionId,
            account_id: values.accountId,
            location_id: values.locationId,
            name: values.name,
            cost: values.cost,
            eventDateTime: new Date(values.date).toISOString(),
            org_id: user?.orgId,
          },
        },
      });

      pubSubInstance?.publish({
        channel: "text_channel",
        message: {
          event: data.update_events_by_pk,
          type: "event_updated",
        },
      });

      toast({
        description: "Event updated successfully",
      });
    } else {
      toast({
        description: "Creating event...",
      });

      await createEventMutation({
        variables: {
          object: {
            region_id: values.regionId,
            account_id: values.accountId,
            location_id: values.locationId,
            name: values.name,
            cost: values.cost,
            eventDateTime: new Date(values.date).toISOString(),
            org_id: user?.orgId,
          },
        },
      });

      toast({
        description: "Event created successfully",
      });
    }

    navigate("/events");
  };

  const searchableRegionResults =
    regionsData?.regions.map((a) => ({ label: a.name, value: a.id })) ?? [];
  const searchableAccountResults =
    accountsData?.accounts.map((a) => ({ label: a.name, value: a.id })) ?? [];
  const searchableLocationResults =
    locationsData?.locations.map((l) => ({ label: l.name, value: l.id })) ?? [];

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-row space-x-8">
          <div className="flex flex-row items-end">
            <SearchableSelect
              defaultValue={eventData?.events_by_pk?.region?.name}
              form={form}
              label="Region"
              name="regionId"
              onSearch={setSearchRegionName}
              searchPlaceholder="Search for a region..."
              selectPlaceholder="Select a region"
              searchResults={searchableRegionResults}
              loadingData={loadingRegions}
              loadingValue={loadingEvent}
            />
            {/* <Button variant="ghost" onClick={onCreateAccount}>
              <Plus />
            </Button> */}
          </div>

          <div className="flex flex-row items-end">
            <SearchableSelect
              defaultValue={eventData?.events_by_pk?.account?.name}
              form={form}
              label="Account"
              name="accountId"
              onSearch={setSearchAccountName}
              searchPlaceholder="Search for an account..."
              selectPlaceholder="Select an account"
              searchResults={searchableAccountResults}
              loadingData={loadingAccounts}
              loadingValue={loadingEvent}
            />
            <Button variant="ghost" onClick={onCreateAccount}>
              <Plus />
            </Button>
          </div>

          <div className="flex flex-row items-end">
            <SearchableSelect
              defaultValue={eventData?.events_by_pk?.location?.name}
              form={form}
              label="Location"
              name="locationId"
              onSearch={setSearchLocationName}
              searchPlaceholder="Search for a location..."
              selectPlaceholder="Select a location"
              searchResults={searchableLocationResults}
              loadingData={loadingLocations}
              loadingValue={loadingEvent}
            />
            <Button variant="ghost" onClick={onCreateLocation}>
              <Plus />
            </Button>
          </div>
        </div>

        <div className="flex flex-row space-x-8">
          <FormField
            control={form.control}
            name="cost"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event Cost</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>

                      <Input
                        {...field}
                        autoFocus
                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                      />
                    </div>
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event name</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Input
                      placeholder="The Oscars' after party"
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>Event date</FormLabel>
              {loadingEvent && <FormFieldSkeleton />}
              {!loadingEvent && (
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          "w-[240px] border-input bg-transparent pl-3 text-left font-normal text-black",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarDays className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={(e) => {
                        field.onChange(e);
                        setIsCalendarOpen(false);
                      }}
                      fromDate={new Date()}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              )}
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-row space-x-8">
          <FormField
            control={form.control}
            name="publicNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Public Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter public notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="hostNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Host Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter host notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="adminNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Admin Notes</FormLabel>
                {loadingEvent && <FormFieldSkeleton />}
                {!loadingEvent && (
                  <FormControl>
                    <Textarea
                      placeholder="Enter admin notes..."
                      {...field}
                      autoFocus
                    />
                  </FormControl>
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormItem>
            <FormLabel>Hosts</FormLabel>
            <FormControl>
              <Input disabled />
            </FormControl>
          </FormItem>

          {/* <div>{JSON.stringify(form.getValues(), null, 2)}</div> */}
          <HostEditTable
            totalCost={form.getValues("cost")}
            hosts={hosts}
            setHosts={setHosts}
          />
        </div>

        <div className="flex justify-between">
          {!eventId && (
            <Button type="submit" disabled={creatingEvent}>
              {creatingEvent ? "Creating..." : "Create"}
            </Button>
          )}
          {eventId && (
            <Button type="submit" disabled={updatingEvent}>
              {updatingEvent ? "Updating..." : "Update"}
            </Button>
          )}
          <Button
            type="button"
            variant="secondary"
            className="cursor-pointer"
            title="Cancel"
            asChild
          >
            <Link to="/events">Cancel</Link>
          </Button>
        </div>
      </form>
    </Form>
  );
}
