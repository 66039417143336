/* eslint-disable */

export const updateObjectArray = (arrayData: any[], newData: any) => {
  const indexToUpdate = arrayData.findIndex((item) => item.id === newData.id);

  if (indexToUpdate !== -1) {
    const updatedArray = arrayData.map((item) => {
      if (item.id === newData.id) {
        return { ...item, ...newData };
      } else {
        return item;
      }
    });
    return updatedArray;
  } else {
    arrayData.push(newData);
    return arrayData;
  }
};

export const compareAndUpdateArray = async (
  array1: Record<string, string>[],
  array2: Record<string, string>[]
) => {
  return array1.map((item1) => {
    const matchingItem = array2.find((item2) => item2.id === item1.id);
    return matchingItem ? { ...item1, ...matchingItem } : item1;
  });
};

export const removeArrayItem = (array: any, object: any) => {
  return array.filter((item: any) => item.id !== object.id);
};

export const addArrayItem = (array1: Array<any>, array2: any) => {
  return [...array1, array2];
};
