import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Input, Paragraph, SimpleButton } from "../components/Common";
import { PasswordRegex } from "../types";
import { useAuth } from "src/hooks/useAuth";

export const ResetPassword = () => {
  const { confirmForgotPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      resetCode: "",
      newPassword: "",
    },
  });

  useEffect(() => {
    setUserEmail(location?.state?.email);
  }, [location?.state?.email]);

  const handleResetPassword = async () => {
    setIsLoading(true);

    const values = getValues();

    const success = await confirmForgotPassword(
      userEmail,
      values.newPassword,
      values.resetCode
    );

    if (success) {
      setIsLoading(false);
      navigate(`/login`);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Paragraph text="Reset password" />
        <StyledForm onSubmit={handleSubmit(handleResetPassword)}>
          <FormContainer>
            <Controller
              name="resetCode"
              control={control}
              rules={{
                required: "Password reset code is required",
                minLength: 6,
              }}
              render={({ field, fieldState }) => (
                <Input
                  label="Password reset code"
                  required
                  name={field.name}
                  handleChange={field.onChange}
                  value={field.value}
                  error={fieldState.error && fieldState.error.message}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: "New password is required",
                pattern: PasswordRegex,
              }}
              render={({ field, fieldState }) => (
                <Input
                  type="password"
                  label="New password"
                  required
                  name={field.name}
                  handleChange={field.onChange}
                  value={field.value}
                  error={fieldState.error && fieldState.error.message}
                />
              )}
            />

            <SimpleButton
              height="40px"
              type="submit"
              onClick={() => {}}
              label="Reset Password"
              background="#0057FF"
              color=" #f0f5ff"
              isLoading={isLoading}
            />
          </FormContainer>
        </StyledForm>
        {/* {Cognito.hosted_ui_signup_url && (
          <div>
            <a href={Cognito.hosted_ui_signup_url}>Hosted Sign Up</a>
          </div>
        )} */}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  margin: auto;
  width: 40%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  border: 1px solid #f0f5ff;
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
`;
