import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { Pencil } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DataTable } from "src/components/RadixWrapper";
import { Hosts } from "src/generated/graphql";
import { HostForm } from "./Form";

type HostsPropsType = {
  hosts: Hosts[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
};

const columnHelper = createColumnHelper<Hosts>();

const defaultColumns: ColumnDef<Hosts, any>[] = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("nickname", {
    header: () => <span className="pl-4">Name</span>,
    cell: (props) => (
      <Button variant="link">
        <Link to={`/hosts/${props.row.getValue("id")}`}>
          {props.getValue()}
        </Link>
      </Button>
    ),
  }),
  columnHelper.accessor("googleAccountEmail", {
    header: "Email",
  }),
  // columnHelper.accessor("eventHosts_aggregate.aggregate.count", {
  //   id: "hostCount",
  //   header: () => <span className="pl-4">Event Count</span>,
  //   cell: (props) => props.getValue().toString(),
  // }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 96,
    cell: function ActionCell({ row }) {
      const [showEditDialog, setShowEditDialog] = useState(false);

      const toggleHostFormModal = () => {
        setShowEditDialog((wasEditing) => {
          return !wasEditing;
        });
      };

      return (
        <div className="flex justify-center gap-2" key="read-mode">
          <Button variant="ghost" size="icon">
            <Pencil className="h-4 w-4" onClick={toggleHostFormModal} />
          </Button>
          {/* <Button variant="ghost" size="icon">
            <Trash2
              className="h-4 w-4"
              onClick={() =>
                table.options.meta?.onDeleteRow(hostId, hostName)
              }
            />
          </Button> */}
          {showEditDialog && (
            <HostForm
              showHost={true}
              toggleShowHost={toggleHostFormModal}
              host={row.original}
            />
          )}
        </div>
      );
    },
  }),
];

export function HostsList({
  hosts,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
  onUpdateRow,
}: HostsPropsType) {
  return (
    <DataTable
      columns={defaultColumns}
      data={hosts ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
    />
  );
}
