import {
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import { Pencil } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DataTable } from "src/components/RadixWrapper";
import { Locations } from "src/generated/graphql";
import { LocationForm } from "./Form";

type LocationsPropsType = {
  locations: Locations[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
};

const columnHelper = createColumnHelper<Locations>();

const defaultColumns = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("name", {
    header: () => <span className="pl-4">Name</span>,
    cell: (props) => (
      <Button variant="link">
        <Link to={`/locations/${props.row.getValue("id")}`}>
          {props.getValue()}
        </Link>
      </Button>
    ),
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.accessor("name", {
    header: () => <span className="pl-4">Address</span>,
    cell: (props) => (
      <>
        {props.row.original.address}, {props.row.original.city},{" "}
        {props.row.original.state} {props.row.original.zip}
      </>
    ),
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 96,
    cell: function ActionCell({ row }) {
      const [showEditDialog, setShowEditDialog] = useState(false);
      // const locationId = row.getValue("id") as string;
      // const locationName = row.getValue("name") as string;

      const toggleLocationFormModal = () => {
        setShowEditDialog((wasEditing) => {
          return !wasEditing;
        });
      };

      return (
        <div className="flex justify-center gap-2" key="read-mode">
          <Button variant="ghost" size="icon">
            <Pencil className="h-4 w-4" onClick={toggleLocationFormModal} />
          </Button>
          {/* <Button variant="ghost" size="icon">
            <Trash2
              className="h-4 w-4"
              onClick={() =>
                table.options.meta?.onDeleteRow(locationId, locationName)
              }
            />
          </Button> */}
          {showEditDialog && (
            <LocationForm
              showLocation={true}
              toggleShowLocation={toggleLocationFormModal}
              location={row.original}
            />
          )}
        </div>
      );
    },
  }),
];

export function LocationsList({
  locations,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
  onUpdateRow,
}: LocationsPropsType) {
  return (
    <DataTable
      columns={defaultColumns}
      data={locations ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
    />
  );
}
