import React, { useState } from "react";
import {
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  DataTable,
  Button,
  SpecificFunctionTypes,
} from "src/components/RadixWrapper";
import { Categories } from "src/generated/graphql";
import { Merge, Pencil, Trash2 } from "lucide-react";
import { Link } from "react-router-dom";
import { CategoryForm } from "./Form";

type CategoriesPropsType = {
  categories: Categories[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
  openMergeDialog: (id: string) => void;
};

type MergeDialogProps = {
  categoryId: string;
  categoryName: string;
};

const columnHelper = createColumnHelper<Categories>();

const defaultColumns = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("name", {
    header: () => <span className="pl-4">Name</span>,
    cell: (props) => (
      <Button variant="link">
        <Link to={`/categories/${props.row.getValue("id")}`}>
          {props.getValue()}
        </Link>
      </Button>
    ),
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.display({
    id: "questionsCount",
    header: () => <span># of questions</span>,
    cell: ({ row }) => (
      <span>{row.original.questions_aggregate.aggregate?.count || 0}</span>
    ),
    size: 192,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 96,
    cell: function ActionCell({ row, table }) {
      const [showEditDialog, setShowEditDialog] = useState(false);
      const categoryId = row.getValue("id") as string;
      const categoryName = row.getValue("name") as string;

      const toggleCategoryFormModal = () => {
        setShowEditDialog((wasEditing) => {
          return !wasEditing;
        });
      };

      return (
        <div className="flex justify-center gap-2" key="read-mode">
          <Button variant="ghost" size="icon">
            <Pencil className="h-4 w-4" onClick={toggleCategoryFormModal} />
          </Button>
          <Button variant="ghost" size="icon">
            <Trash2
              className="h-4 w-4"
              onClick={() =>
                table.options.meta?.onDeleteRow(categoryId, categoryName)
              }
            />
          </Button>

          <Button
            variant="ghost"
            size="icon"
            title="Merge This Category"
            onClick={() =>
              table.options.meta?.onTriggerNonGenericFunction(
                SpecificFunctionTypes.CATEGORY_MERGE_DIALOG_OPEN,
                { categoryId }
              )
            }
          >
            <Merge className="h-4 w-4" />
          </Button>
          {showEditDialog && (
            <CategoryForm
              showCategory={true}
              toggleShowCategory={toggleCategoryFormModal}
              category={row.original}
            />
          )}
        </div>
      );
    },
  }),
];

export function CategoriesList({
  categories,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
  onUpdateRow,
  openMergeDialog,
}: CategoriesPropsType) {
  const handleOnTriggerNonGenericFunction = (
    type: SpecificFunctionTypes,
    params?: Record<string, unknown>
  ) => {
    if (type === SpecificFunctionTypes.CATEGORY_MERGE_DIALOG_OPEN && params) {
      const { categoryId } = params as MergeDialogProps;
      openMergeDialog(categoryId);
    }
  };
  return (
    <DataTable
      columns={defaultColumns}
      data={categories ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
      onTriggerNonGenericFunction={handleOnTriggerNonGenericFunction}
    />
  );
}
