import { useQuery } from "@apollo/client";
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  createColumnHelper,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { Check, Pencil, Trash2, X } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { EditableCell } from "src/components/Common";
import { Get_Categories } from "src/gql";
import { useAuth } from "src/hooks/useAuth";
import { useAppSelector, usePubSubInstance } from "src/store";
import { QuestionsInterface } from "src/types/Questions";
import { QuestionsPageColumns } from "src/types/Settings";
import { Button, DataTable } from "../RadixWrapper";
import { CreateEditQuestion } from "./CreateEditQuestion";

type QuestionsListProps = {
  questions: QuestionsInterface[] | undefined;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPaginationChange: OnChangeFn<PaginationState>;
  loadingData: boolean;
  onDeleteRow: (id: string, name: string) => Promise<void>;
  onUpdateRow: (params: Record<string, unknown>) => Promise<void>;
  isCVOpen: boolean;
  onCVOpenChange: (open: boolean) => void;
};

const columnHelper = createColumnHelper<QuestionsInterface>();

const defaultColumns: ColumnDef<QuestionsInterface, any>[] = [
  columnHelper.accessor("id", {
    header: "ID",
  }),
  columnHelper.accessor("category.id", {
    header: "Category",
    cell: function CategoryCellWrapper(props) {
      const { data, loading } = useQuery(Get_Categories);

      if (!loading) {
        return (
          <EditableCell<QuestionsInterface>
            options={data.categories}
            {...props}
          />
        );
      }

      return null;
    },
    // cell: (props) => (
    // ),
    meta: {
      type: "select",
      optionsQuery: Get_Categories,
    },
    size: 80,
  }),
  columnHelper.accessor("question", {
    header: "Question",
    cell: function QuestionCellWrapper(props) {
      const questionId = props.row.getValue("id") as string;

      const { editingQuestions } = useAppSelector((state) => {
        return {
          editingQuestions: state.questionReducer.editingQuestions,
        };
      });

      const isBeingEdited = editingQuestions.some((eq) => eq.id === questionId);

      return (
        <EditableCell<QuestionsInterface>
          isBeingEdited={isBeingEdited}
          {...props}
        />
      );
    },
    size: Number.MAX_SAFE_INTEGER,
  }),
  columnHelper.accessor("answer", {
    header: "Answer",
    cell: (props) => <EditableCell<QuestionsInterface> {...props} />,
    size: 384,
  }),
  // columnHelper.accessor("eventQuestions_aggregate.aggregate.count", {
  //   header: "Uses",
  //   cell: (v) => (
  //     <div>
  //       {v.row.original?.eventQuestions_aggregate?.aggregate.count || ""}
  //     </div>
  //   ),
  //   size: 50,
  // }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 80,
  }),
  columnHelper.accessor("questionType.id", {
    header: "Type",
    cell: (v) => <div>{v.row.original?.questionType?.name || ""}</div>,
    size: 80,
  }),

  columnHelper.accessor("created_at", {
    header: "Created",
    cell: (v) => (
      <div
        title={dayjs(v.row.original?.created_at).format("M/D/YYYY h:mm:ss A")}
      >
        {v.row.original?.created_at
          ? `${dayjs().diff(dayjs(v.row.original.created_at), "day")} days old`
          : ""}
      </div>
    ),
    size: 80,
  }),
  columnHelper.accessor("updated_at", {
    header: "Updated",
    cell: (v) => (
      <div
        title={dayjs(v.row.original?.updated_at).format("M/D/YYYY h:mm:ss A")}
      >
        {dayjs(v.row.original?.updated_at).format("M/D/YYYY") || ""}
      </div>
    ),
    size: 80,
  }),
  columnHelper.display({
    id: "actions",
    header: () => (
      <span className="inline-flex w-full justify-center">Actions</span>
    ),
    size: 100,
    cell: function ActionCell({ row, table }) {
      const [showQDialog, setShowQDialog] = useState(false);
      const { pubSubInstance } = usePubSubInstance();
      const questionId = row.getValue("id") as string;
      const questionText = row.getValue("question") as string;
      const questionAnswer = row.getValue("answer") as string;
      const questionCategory = row.getValue("category_id") as string;
      // const questionTypeId = row.getValue("type_id") as string;
      // const questionStatus = row.getValue("status") as string;
      const meta = table.options.meta;

      // No longer track that this cell was being edited
      const setEditedRows = useCallback(async () => {
        meta?.setEditedRows((old) => {
          const isEditing = !old[row.id];

          if (isEditing) {
            pubSubInstance?.publish({
              channel: "text_channel",
              message: { type: "editing_form", question: row.original },
            });
          } else {
            pubSubInstance?.publish({
              channel: "text_channel",
              message: { type: "editing_canceled", question: row.original },
            });
          }

          return {
            ...old,
            [row.id]: !old[row.id],
          };
        });
      }, [meta, pubSubInstance, row.id, row.original]);

      const dataHasChanged = () => {
        const question = meta?.rawData[row.index] as QuestionsInterface;
        const originalQText = question.question ?? "";
        const originalQCategory = question.category.name ?? "";
        const originalQAnswer = question.answer ?? "";
        return (
          (questionText &&
            questionText.length > 0 &&
            originalQText !== questionText) ||
          (questionAnswer &&
            questionAnswer.length > 0 &&
            originalQAnswer !== questionAnswer) ||
          (questionCategory &&
            questionCategory.length > 0 &&
            originalQCategory !== questionCategory)
        );
      };

      // Save any updates to this cell's siblings
      const saveRowUpdates = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        e.preventDefault();

        const dataChanged = dataHasChanged();
        // Save the updates only if there was a change
        if (dataChanged) {
          meta?.onUpdateRow(
            row.index,
            {
              questionId,
              question: questionText,
              answer: questionAnswer,
              category: questionCategory,
            },
            {}
          );
        }

        if (
          questionText.length > 0 &&
          questionAnswer.length > 0 &&
          questionCategory.length > 0
        ) {
          // Ensure that we are no longer tracking edits
          await setEditedRows();
        }
      };

      const cancelEdit = useCallback(
        async (
          e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent
        ) => {
          e.preventDefault();

          meta?.cancelEdit(row.index);
          await setEditedRows();
        },
        [meta, row.index, setEditedRows]
      );

      const toggleAddQuestionModal = () => {
        setShowQDialog((wasEditing) => {
          if (!wasEditing) {
            pubSubInstance?.publish({
              channel: "text_channel",
              message: { type: "editing_form", question: row.original },
            });
          } else {
            pubSubInstance?.publish({
              channel: "text_channel",
              message: { type: "editing_canceled", question: row.original },
            });
          }

          return !wasEditing;
        });
      };

      const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
          if (e.key === "Escape") {
            cancelEdit(e);
          }
        },
        [cancelEdit]
      );

      useEffect(() => {
        if (meta?.editedRows[row.id]) {
          document.addEventListener("keydown", onKeyDown);
        } else {
          document.removeEventListener("keydown", onKeyDown);
        }

        return () => {
          document.removeEventListener("keydown", onKeyDown);
        };
      }, [meta?.editedRows, row.id, onKeyDown]);

      if (meta?.editedRows[row.id]) {
        return (
          <div className="flex w-24" key="edit-mode">
            <Button variant="ghost" size="icon" onClick={saveRowUpdates}>
              <Check className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="ml-2"
              onClick={cancelEdit}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        );
      }

      return (
        <div className="flex gap-2" key="read-mode">
          <Button
            variant="ghost"
            size="icon"
            onClick={(e) => {
              e.preventDefault();
              toggleAddQuestionModal();
            }}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();

              meta?.onDeleteRow(questionId, questionText);
            }}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
          {showQDialog && (
            <CreateEditQuestion
              showQuestion={true}
              toggleShowQuestion={toggleAddQuestionModal}
              question={row.original}
            />
          )}
        </div>
      );
    },
  }),
];

// Checks if the items in setting1 are having same value as the items in setting 2
// (Order does not matter - items are identified by their name field)
function areSettingsEqual(
  setting1: QuestionsPageColumns[],
  setting2: QuestionsPageColumns[]
) {
  if (setting1.length !== setting2.length) {
    return false;
  }

  const setting1Map = new Map(setting1.map((s) => [s.name, s.isVisible]));

  for (const item of setting2) {
    if (
      !setting1Map.has(item.name) ||
      setting1Map.get(item.name) !== item.isVisible
    ) {
      return false;
    }
  }

  return true;
}

export function QuestionList({
  questions,
  pageIndex,
  pageSize,
  totalCount,
  onPaginationChange,
  loadingData,
  onDeleteRow,
  onUpdateRow,
  isCVOpen,
  onCVOpenChange,
}: QuestionsListProps) {
  const { user, updateUser } = useAuth();

  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
    {}
  );

  // On page load, load the user's settings for this page
  // If none exists, set up default settings
  useEffect(() => {
    let configuredColumnVisibility = {} as Record<string, boolean>;

    if (user?.settings?.questionsPage) {
      user?.settings.questionsPage.columns.forEach(
        (c) => (configuredColumnVisibility[c.name] = c.isVisible)
      );
    } else {
      configuredColumnVisibility = {
        id: false,
        "category.id": true,
        question: true,
        answer: false,
      };
    }

    setVisibleColumns(configuredColumnVisibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the visible columns change, save the update in db
  // and in our app
  useEffect(() => {
    (async () => {
      const columns = Object.keys(visibleColumns).map((k) => ({
        name: k,
        isVisible: visibleColumns[k],
        sortOrder: null,
      }));

      if (columns.length === 0) {
        // A possibility on component load. Ignore until we get the full picture
        return;
      } else {
        // Check if there is any change in the settings
        if (
          areSettingsEqual(
            columns,
            user?.settings?.questionsPage?.columns ?? []
          )
        ) {
          return;
        }
      }

      // All checks passed. We indeed have settings that have changed. Update both db and our app
      const settings = {
        ...user?.settings,
        version: 1,
        questionsPage: { columns },
      };

      await updateUser({ settings });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns]);

  return (
    <DataTable
      columns={defaultColumns}
      data={questions ?? []}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onPaginationChange={onPaginationChange}
      loadingData={loadingData}
      onDeleteRow={onDeleteRow}
      onUpdateRow={onUpdateRow}
      isRowDraggable={true}
      showToggleVisibility={true}
      columnVisibility={visibleColumns}
      setColumnVisibility={setVisibleColumns}
      isCVOpen={isCVOpen}
      onCVOpenChange={onCVOpenChange}
    />
  );
}
