import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Input, SimpleButton } from "../components/Common";
import { useAuth } from "src/hooks/useAuth";

interface LoginInterface {
  username: string;
  password: string;
}

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleFormSubmit = async (data: LoginInterface) => {
    setIsLoading(true);

    const success = await signIn(data.username, data.password);

    setIsLoading(false);

    if (success === "FORCERESETPASSWORD") {
      navigate("/force-change-password");
    } else if (success) {
      navigate("/home");
    }
  };

  return (
    <div className="container mx-auto">
      <div className="mx-80">
        <img src="/what_trivia_logo_blue_rgb.png" width={200} />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            name="username"
            control={control}
            rules={{
              required: "Username is required",
              minLength: 5,
            }}
            render={({ field, fieldState }) => (
              <Input
                label="Email"
                required
                name={field.name}
                handleChange={field.onChange}
                value={field.value}
                error={fieldState.error && fieldState.error.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
            }}
            render={({ field, fieldState }) => (
              <Input
                label="Password"
                required
                name={field.name}
                handleChange={field.onChange}
                value={field.value}
                error={fieldState.error && fieldState.error.message}
                type="password"
              />
            )}
          />
          <SimpleButton
            height="40px"
            onClick={handleSubmit(handleFormSubmit)}
            label="Login"
            background="#0057FF"
            color=" #f0f5ff"
            isLoading={isLoading}
          />
          <br />
          <Link
            className="text-blue-900 hover:text-blue-700"
            to="/forgot-password"
          >
            Forgot password ?{" "}
          </Link>
        </form>
      </div>
    </div>
  );
};
