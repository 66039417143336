import React, { ChangeEvent } from "react";
import { Paragraph } from "./Paragraph";
import { Input as RadixInput } from "src/components/RadixWrapper";

interface InputProps {
  placeholder?: string;
  width?: string;
  background?: string;
  height?: string;
  padding?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderradius?: string;
  error?: string;
}

interface InputInterface extends InputProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string;
  name?: string;
  type?: string;
  required?: boolean;
  containerTop?: string;
  error?: string;
}

// TODO - Replace this with Radix UI's Form
export const Input = (props: InputInterface) => {
  return (
    <div className="mb-4 mt-2">
      <div className="flex gap-1">
        <span>{props?.label || ""}</span>
        {props.required && <Paragraph color="red" text="*" />}
      </div>

      <RadixInput
        placeholder={props.placeholder}
        onChange={props.handleChange}
        required={props.required}
        value={props.value}
        name={props.name}
        type={props.type}
      />
      {props.error && (
        <Paragraph fontSize="12px" color="red" text={props.error} />
      )}
    </div>
  );
};
