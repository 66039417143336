import { gql } from "@apollo/client";

export const SEARCH_HOSTS = gql`
  query SearchHosts($limit: Int, $offset: Int, $where: hosts_bool_exp) {
    hosts(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { nickname: asc }
    ) {
      id
      nickname
      googleAccountEmail
      rates
      isActive
      dateStarted
      dateEnded
      eventHosts_aggregate {
        aggregate {
          count
        }
      }
    }
    hosts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_HOST_BY_ID = gql`
  query GetHostById($id: uuid!) {
    hosts_by_pk(id: $id) {
      id
      nickname
      googleAccountEmail
      rates
      eventHosts_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const Create_Host_Mutation = gql`
  mutation Insert_Host($object: hosts_insert_input!) {
    insert_hosts_one(object: $object) {
      id
      nickname
      googleAccountEmail
      rates
    }
  }
`;

export const Update_Host_Mutation = gql`
  mutation update_hosts_by_pk(
    $pk_columns: hosts_pk_columns_input!
    $_set: hosts_set_input!
  ) {
    update_hosts_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      nickname
      googleAccountEmail
      rates
    }
  }
`;

export const Delete_Host_Mutation = gql`
  mutation delete_hosts_by_pk($id: uuid!) {
    delete_hosts_by_pk(id: $id) {
      id
      nickname
      googleAccountEmail
      rates
    }
  }
`;
