import React from "react";
import { EventQuestions } from "src/generated/graphql";
import { LoadingAnimation } from "../Common";
import { EventQuestionListItem } from "./EventQuestionListItem";

interface EventQuestionListProps {
  questions: EventQuestions[];
}

export const EventQuestionList: React.FC<EventQuestionListProps> = ({
  questions,
}: EventQuestionListProps) => {
  // const { pubSubInstance } = usePubSubInstance();
  // const { events } = useAppSelector((state) => {
  //   return {
  //     events: state.eventReducer.events,
  //   };
  // });

  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const listener = {
  //     status: (statusEvent: Pubnub.StatusEvent) => {
  //       if (statusEvent.category === "PNConnectedCategory") {
  //         console.log("Connected");
  //       }
  //     },
  //     message: async (event: Pubnub.MessageEvent) => {
  //       if (event.message.type === "editing_event_form") {
  //         dispatch(
  //           triggerEditEvent({
  //             isEditing: true,
  //             event: event.message.event,
  //           })
  //         );
  //       }

  //       if (event.message.type === "editing_event_canceled") {
  //         dispatch(editingEventCanceled({ id: event?.message?.event?.id }));
  //       }

  //       if (event.message.type === "event_updated") {
  //         dispatch(editingEventCanceled({ id: event?.message?.event?.id }));

  //         dispatch(setEvents({ events: event.message.events }));
  //       }
  //     },
  //   };

  //   pubSubInstance?.addListener(listener);

  //   pubSubInstance?.subscribe({
  //     withPresence: true,
  //     channels: ["text_channel"],
  //   });

  //   return () => {
  //     pubSubInstance?.removeListener(listener);
  //     pubSubInstance?.unsubscribeAll();
  //   };
  // }, [pubSubInstance, dispatch]);

  return (
    <div className="mt-8 flex flex-col p-5">
      <div className="flex flex-grow border-b border-gray-300 pb-4">
        <div className="w-1/10 flex md:w-full">
          <div>#</div>
        </div>

        <div className="flex w-1/2 md:w-full">
          <div>Question</div>
        </div>

        <div className="w-3/10 flex md:w-full">
          <div>Answer</div>
        </div>
      </div>
      <div className="flex w-full flex-col">
        {questions ? (
          questions.map((item) => (
            <EventQuestionListItem {...item} key={item.id} />
          ))
        ) : (
          <LoadingAnimation />
        )}
      </div>
    </div>
  );
};
