import { useMutation } from "@apollo/client";
import React from "react";
import { QuickCreateEntity } from "src/components/Common";
import { useToast } from "src/components/RadixWrapper/UseToast";
import { GetRegionsQuery } from "src/generated/graphql";
import { Create_Account_Mutation, GET_REGIONS } from "src/gql";
import { QCFormFieldType } from "src/types/QuickCreate";
import { z } from "zod";
import FormFieldSkeleton from "./Skeleton";

type NewAccountQuickCreateType = {
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const newAccountSchema = z.object({
  name: z.string().min(1, { message: "Account name is required" }),
  region_id: z.string(),
});

const formFields: QCFormFieldType[] = [
  {
    name: "name",
    label: "Enter the account name",
    placeholder: "eg. Phil's Discount Furniture",
    type: "text",
  },
  {
    name: "region_id",
    label: "Which region to associate the account to?",
    placeholder: "Select a region",
    query: GET_REGIONS,
    queryParser: (data: unknown) => {
      const getRegionsResponse: GetRegionsQuery = data as GetRegionsQuery;

      return getRegionsResponse.regions.map((r) => ({
        label: r.name,
        value: r.id,
      }));
    },
    suspenseFallback: <FormFieldSkeleton />,
    type: "select",
  },
];

export default function NewAccountQuickCreate({
  isOpen,
  onOpenChange,
}: NewAccountQuickCreateType) {
  const [createAccountMutation, { loading }] = useMutation(
    Create_Account_Mutation
  );

  const { toast } = useToast();

  const onCreate = async (values: z.infer<typeof newAccountSchema>) => {
    toast({
      description: `Quickly creating an account with name ${values.name}`,
    });

    try {
      await createAccountMutation({
        variables: {
          object: {
            name: values.name,
            region_id: values.region_id,
          },
        },
      });

      toast({
        description: `Account created successfully`,
      });
    } catch (err) {
      console.log(err);

      toast({
        variant: "destructive",
        description: `Account creation failed. Please check console logs for error details`,
      });

      return false;
    }

    return true;
  };

  return (
    <QuickCreateEntity
      creating={loading}
      defaultValues={{ name: "", region_id: "" }}
      entityName="Account"
      formFields={formFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onSubmit={onCreate}
      schema={newAccountSchema}
    />
  );
}
