import gql from "graphql-tag";

export const Get_NoteBooks_By_Id = gql`
  query GetNotebooksById($id: uuid!) {
    notebooks_by_pk(id: $id) {
      id
      owner_id
      name
      isArchived
      notebooks_notebookQuestions {
        id
        question_id
        sortOrder
        notebookQuestions_question {
          id
          question
          answer
          category {
            id
            name
          }
          created_at
          updated_at
          category_id
        }
      }
      isStarred
    }
  }
`;
