import React from "react";
import Dropdown from "../Common/Dropdown";
import { Accounts, useGetAccountsQuery } from "src/generated/graphql";
import { Button } from "src/components/RadixWrapper";
import { Plus } from "lucide-react";

interface AccountsSelectProps {
  // accounts: Account[];
  selectedAccount: Accounts | undefined;
  onAccountChange: (account: Accounts) => void;
  onCreateButtonClick?: () => void;
}

const AccountsSelect: React.FC<AccountsSelectProps> = ({
  selectedAccount,
  onAccountChange,
  onCreateButtonClick,
}) => {
  const [filterValue, setFilterValue] = React.useState("");
  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   onAccountChange(event.target.value);
  // };

  const { data } = useGetAccountsQuery({
    variables: {
      limit: 50,
      where: { name: { _ilike: `%${filterValue}%` } },
    },
    fetchPolicy: "network-only",
  });
  const onFilterStart = (filter: string) => {
    // console.log("Filtering", filter);
    setFilterValue(filter);
  };
  const onChange = (account: Accounts) => {
    // console.log("Selected account:", account);
    onAccountChange(account);
  };

  // if (!data) return <></>;

  return (
    <div className="flex flex-row items-end">
      <Dropdown<Accounts>
        selectedItem={selectedAccount}
        idColumn="id"
        labelColumn="name"
        items={data?.accounts || []}
        onSelect={onChange}
        setFilter={onFilterStart}
      />
      {onCreateButtonClick && (
        <Button variant="ghost" onClick={onCreateButtonClick}>
          <Plus />
        </Button>
      )}
    </div>
  );
};

export default AccountsSelect;
