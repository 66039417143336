/**
 * ! DO NOT USE Sheets component here. I faced the following issues:
 * ! - If I add Select component in the form, the sheet would open, then close and then open again
 * ! - I could not disable event propagation if I clicked on any of the action column buttons (Note that
 * !   the entire row in Host table also has a click handler on it - both would trigger). This is how
 * !   Portals behave apparently, but event.stopPropogation() did not help
 */
import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { XCircle } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea,
} from "src/components/RadixWrapper";
import { Hosts } from "src/generated/graphql";
import { Create_Host_Mutation, Update_Host_Mutation } from "src/gql";
import * as z from "zod";
import { useToast } from "../RadixWrapper/UseToast";

const formSchema = z.object({
  nickname: z.string().min(1),
  googleAccountEmail: z.string().min(1),
  // rates: z.string().min(1),
});

type CreateHostFormType = {
  showHost: boolean;
  toggleShowHost: () => void;
  host?: Hosts;
};

export function HostForm({
  showHost,
  toggleShowHost,
  host,
}: CreateHostFormType) {
  const { toast } = useToast();
  const [createHostMutation] = useMutation(Create_Host_Mutation);
  const [updateHost] = useMutation(Update_Host_Mutation);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: host
      ? {
          nickname: host.nickname ?? "",
          googleAccountEmail: host.googleAccountEmail ?? "",
        }
      : {
          nickname: "",
          googleAccountEmail: "",
        },
  });
  // const { pubSubInstance } = usePubSubInstance();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    toggleShowHost();

    toast({
      title: host
        ? "Updating the host with the following values:"
        : "Creating a host with the following values:",
      description: (
        <pre className="mt-2 w-[340px] whitespace-pre-wrap rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(values, null, 2)}</code>
        </pre>
      ),
    });

    try {
      if (host) {
        // const { data } = await updateHost({
        await updateHost({
          variables: {
            pk_columns: { id: host.id },
            _set: {
              nickname: values.nickname,
              googleAccountEmail: values.googleAccountEmail,
            },
          },
        });

        // pubSubInstance?.publish({
        //   channel: "text_channel",
        //   message: {
        //     host: data.update_hosts_by_pk,
        //     type: "host_updated",
        //   },
        // });
      } else {
        await createHostMutation({
          variables: {
            object: {
              nickname: values.nickname,
              googleAccountEmail: values.googleAccountEmail,
            },
          },
        });
      }

      toast({
        title: "SUCCESS",
        description: host
          ? "Host updated successfully"
          : "New host created successfully",
      });

      form.reset();
    } catch (err) {
      if (host) {
        console.log("An error occurred during host update");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not update the host. Try again.`,
        });
      } else {
        console.log("An error occurred during host creation");
        console.log(err);

        toast({
          variant: "destructive",
          title: "FAILED",
          description: `Could not create the host. Try again.`,
        });
      }
    }
  };

  return (
    <div
      className={clsx({
        "fixed inset-0 z-10 h-full w-full": true,
        invisible: !showHost,
      })}
    >
      <div
        onClick={toggleShowHost}
        className={clsx({
          "absolute inset-0 h-full w-full bg-gray-900": true,
          "transition-all duration-500 ease-out": true,
          "opacity-0": !showHost,
          "opacity-50": showHost,
        })}
      ></div>
      <div
        className={clsx({
          "absolute right-0 h-full w-96 bg-white": true,
          "transition-all duration-300 ease-out": true,
          "translate-x-full": !showHost,
        })}
      >
        <div className="flex flex-col space-y-2 px-6 pt-6">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-foreground">
              {host ? "Edit Host" : "Create Host"}
            </h2>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none"
              onClick={toggleShowHost}
            >
              <XCircle className="h-4 w-4" />
            </Button>
          </div>
          <p className="text-sm text-muted-foreground">
            Here you can {host ? "edit an existing host" : "create a new host"}
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 p-6"
          >
            <FormField
              control={form.control}
              name="nickname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Enter the host name</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Jimmy" rows={4} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="googleAccountEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Google Account Email</FormLabel>
                  <FormControl>
                    <Textarea placeholder="me@google.com" rows={4} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">{host ? "Save" : "Create Host"}</Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
