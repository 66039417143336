import React from "react";

import styled from "styled-components";
import { Loader } from "lucide-react";

interface SimpleButtonProps {
  label?: string;
  type?: "button" | "submit" | "reset";
  onClick: () => void;
  color?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  padding?: string;
  borderradius?: string;
  background?: string;
  isLoading?: boolean;
}

export const SimpleButton: React.FC<SimpleButtonProps> = ({
  label,
  // type,
  onClick,
  padding,
  color,
  borderradius,
  background,
  width,
  height,
  fontWeight,
  fontSize,
  isLoading,
}) => {
  return (
    <Button
      background={background}
      padding={padding}
      color={color}
      onClick={onClick}
      borderradius={borderradius}
      width={width}
      height={height}
      fontWeight={fontWeight}
      fontSize={fontSize}
    >
      {isLoading ? <Loader className="m-auto h-4 w-4" /> : `${label}`}
    </Button>
  );
};

const Button = styled.button<SimpleButtonProps>`
  color: ${({ color }) => (color ? color : "#0057FF")};
  padding: ${({ padding }) => (padding ? padding : "0px")};
  width: ${({ width }) => (width ? width : "100px")};
  height: ${({ height }) => (height ? height : "100px")};
  border-radius: ${({ borderradius }) => (borderradius ? borderradius : "5px")};
  background: ${({ background }) => (background ? background : "#D6E4FF")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "400")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
`;
