import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { Paragraph } from "./Paragraph";

interface ToggleSwitchProps {
  id: string;
  label?: string;
  name?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSwitch = (props: ToggleSwitchProps) => {
  return (
    <div>
      <CheckBoxWrapper>
        <CheckBox onChange={props.handleChange} id={props.id} type="checkbox" />
        <CheckBoxLabel htmlFor={props.id} />
        <LabelContainer>
          <Paragraph fontSize="14px" text={props.label} />
        </LabelContainer>
      </CheckBoxWrapper>
    </div>
  );
};

const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 24px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #5c87ff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;
